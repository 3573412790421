const InvoiceData = [
    {
        id: "#DS0215",
        date: "07 Oct, 2021",
        BillingName: "Connie Franco",
        Amount: "$26.30",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#DS0214",
        date: "05 Oct, 2021",
        BillingName: "Paul Reynolds",
        Amount: "$24.20",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#DS0214",
        date: "06 Oct, 2021",
        BillingName: "Ronald Patterson",
        Amount: "$65.32",
        Status: "Pending",
        Action: "View Details"
    },
    {
        id: "#DS0212",
        date: "07 Oct, 2021",
        BillingName: "Adella Perez",
        Amount: "$53.32",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#DS0211",
        date: "07 Oct, 2021",
        BillingName: "Theresa Mayers",
        Amount: "$13.21",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#DS0210",
        date: "06 Oct, 2021",
        BillingName: "Michael Wallace",
        Amount: "$23.41",
        Status: "Pending",
        Action: "View Details"
    },
    {
        id: "#DS0209",
        date: "07 Oct, 2021",
        BillingName: "Oliver Gonzales",
        Amount: "$41.23",
        Status: "Pending",
        Action: "View Details"
    },
    {
        id: "#DS0208",
        date: "08 Oct, 2021",
        BillingName: "David Burke",
        Amount: "$32.25",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#DS0207",
        date: "09 Oct, 2021",
        BillingName: "Willie Verner",
        Amount: "$53.21",
        Status: "Pending",
        Action: "View Details"
    },
    {
        id: "#DS0206",
        date: "07 Oct, 2021",
        BillingName: "Felix Perry",
        Amount: "$63.21",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#SK4526",
        date: "05 Oct, 2021",
        BillingName: "Stacie Parker",
        Amount: "$63.85",
        Status: "Pending",
        Action: "View Details"
    },
    {
        id: "#SK8522",
        date: "06 Oct, 2021",
        BillingName: "Betty Wilson",
        Amount: "$32.12",
        Status: "Pending",
        Action: "View Details"
    },
    {
        id: "#SK4545",
        date: "07 Oct, 2021",
        BillingName: "Roman Crabtree",
        Amount: "$45.62",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#SK9652",
        date: "07 Oct, 2021",
        BillingName: "Marisela Butler",
        Amount: "$62.35",
        Status: "Pending",
        Action: "View Details"
    },
    {
        id: "#SK4256",
        date: "06 Oct, 2021",
        BillingName: "Roger Slayton",
        Amount: "$45.62",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#SK4125",
        date: "07 Oct, 2021",
        BillingName: "Barbara Torres",
        Amount: "$42.63",
        Status: "Paid",
        Action: "View Details"
    },
    {
        id: "#SK6523",
        date: "08 Oct, 2021",
        BillingName: "Daniel Rigney",
        Amount: "$32.54",
        Status: "Pending",
        Action: "View Details"
    },
    {
        id: "#SK6563",
        date: "09 Oct, 2021",
        BillingName: "Kenneth Linck",
        Amount: "$52.62",
        Status: "Pending",
        Action: "View Details"
    },
]

export { InvoiceData };