import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { AppContext } from '../../components/context/AppContextProvider'//context/AppContextProvider
import axios from 'axios';
import { Card, Col, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
const Requests = () => {
  const {user}=useContext(AppContext);
  const navigate=useNavigate()
  const agent_id=JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [pageLoading,setPageLoading]=useState(false);
  const [meetings,setMettings]=useState([]);
  const [calls,setCalls]=useState([]);
  const [callData,setCallData]=useState({})
  const [showReqModal,setShowReqModal]=useState(false)
  const [calldata,setCalldata]=useState({});
  const getFollowsData=()=>{
    setPageLoading(true)
    const data_send={
      agent_id:agent_id
    }
    console.log(data_send)
    axios.post('https://camp-coding.tech/U_A_D_Walls/admin/follow_up.php',data_send)
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        setMettings(res?.message?.meetings);
        setCalls(res?.message?.callings);
      }
    })
    .catch((e)=>{
      console.log(e);
    })
    .finally(()=>{
      setPageLoading(false)
    })
  }
  useEffect(()=>{
    getFollowsData()
  },[])
  return (
    <>
      {
        pageLoading?
        (
          [1, 2, 3].map(x => (
            <Col  xs={24} md={24} lg={24} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        )
        :
        (
          <div className='py-6 p-6 container'  style={{marginTop:'78px'}}>
            <div>Requests</div>
            <table className="table table-nowrap table-hover mb-0">
      <thead>
          <tr>
              <th scope="col">number</th>
              <th scope="col">id</th>
              <th scope="col">Client Name</th>
              <th scope="col">phone</th>
              <th scope="col">address</th>
              <th scope="col">Answer Details</th>
              <th scope="col">Date To Follow</th>
              <th scope="col">User Offer</th>
              <th scope="col">Time</th>
              <th scope="col">Request</th>
{/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
          </tr>
      </thead>
      <tbody>
        {
          calls&&Array.isArray(calls)&&calls.map((item,index)=>{
            return(
              <tr>
                <th>{index+1}</th>
              <th
              onClick={()=>{
                //navigate("/clientprofile",{state:{data:item}});
                navigate("/clientprofile2",{state:{data:item}});
              }}
              style={{
                cursor:'pointer',
                color:'blue',
                textDecoration:'underline'
              }} scope="row">{item.client_id}</th>
              <td><Link to="#" className="text-dark">{item?.name}</Link></td>
              <td><Link to="#" className="text-dark">{item?.phone}</Link></td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item?.address}</span>
              </td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item?.answer_details}</span>

              </td>
              <td>
              <span className="badge badge-soft-primary font-size-12">{item?.date_to_follow}</span>
              </td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item?.user_offer}</span>
              </td>
              <td>
                {console.log(item)}
                  <span className="badge badge-soft-primary font-size-12">{item?.time}</span>
              </td>
              <td>
                  <button onClick={()=>{
                    setShowReqModal(true)
                    setCalldata(item.request)
                    console.log(item)
                  }} className='btn btn-primary'>Request</button>
              </td>
          </tr>
            )
          })
        }

      </tbody>
                </table>
          </div>
        )
      }
            <Modal title="Request Data" open={showReqModal} onOk={()=>{
        setShowReqModal(false)
      }} onCancel={()=>{
        setShowReqModal(false)
      }}>
                <Card
                  style={{
                    width: '100%',
                  }}
                >
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <p>bathroom:{calldata?.bathroom}</p>
                    <p>bedrooms:{calldata?.bathroom}</p>
                  </div>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>compound name:{calldata?.compound_name}</p>
                  <p>delivery date:{calldata?.delivery_date}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>down payment plan:{calldata?.down_payment_plan}</p>
                  <p>monthly payment plan:{calldata?.monthly_payment_plan}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>finishing:{calldata?.installment_year}</p>
                  <p>finishing:{calldata?.finishing}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>max square meter:{calldata?.max_square_meter}</p>
                  <p>min square meter:{calldata?.min_square_meter}</p>
                  </div>

                 <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>property type:{calldata?.property_type}</p>
                  <p>sale type:{calldata?.sale_type}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>sup location name:{calldata?.sub_location}</p>
                  <p>location name:{calldata?.location_data?.location_name}</p>

                  </div>
                </Card>
      </Modal>
    </>
  )
}

export default Requests
