import React from 'react'
import { useContext } from 'react'
import { AppContext } from '../../components/context/AppContextProvider'
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, Spin } from 'antd';
import { MdEditNote } from "react-icons/md";
import { FidgetSpinner, ThreeDots } from "react-loader-spinner";
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal, Select } from 'antd'
import { toast } from 'react-toastify';
const DevProjects = () => {
  const navigate=useNavigate();
  const {user}=useContext(AppContext);
  const location=useLocation();
  // console.log(location.state.devData.developer_id)
  const [showAddByExcel,setShowAddByExcel]=useState(false)
  const [addLoading,setAddLoading]=useState(false)
  const [loading,setloading]=useState(true);
  const [Projects,setProjects]=useState([]);
  const [rowData,setRowData]=useState({});
  const [showEdit,setShowEdit]=useState(false);
  const [showAdd,setShowAdd]=useState(false);
  const [editLoading,setEditLoading]=useState(false)
  const [newDev,setNewDev]=useState({
    developer_name:'',
    company_name:'',
    company_description:'',
  })
  const [developersFile,setDevelopersFile]=useState(null);
  const getDevelopers=()=>{
    setloading(true)
    const data_send={
      developer_id:location.state.devData.developer_id
    };
    console.log(data_send)
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/dev_projects.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(Array.isArray(res.message)){
        setProjects(res.message)
      }
      console.log(res)
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setloading(false)
    })
  }
  useEffect(()=>{
    getDevelopers()
  },[])

  const handleAddByExcel=()=>{
    setAddLoading(true)
    const formData=new FormData();

    formData.append("file_attachment",developersFile);
    formData.append('developer_id',location.state.devData.developer_id);
    axios.post('https://camp-coding.tech/U_A_D_Walls/admin/upload_excel_file_dev.php',formData)
    .then((res)=>{
      console.log(res)
      toast.success(`done for ${res.add_counter}`)
      toast.warn(`duplication for ${res.duplication_counter}`)
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setAddLoading(false)
    })
  }
  const handleEditDev=()=>{
    setEditLoading(true)
    const data_send={
      ...rowData
    }
    axios.post('https://camp-coding.tech/U_A_D_Walls/admin/edit_project.php',data_send)
    .then((res)=>{
      if(res.status=='success'){
        toast.success(res.message)
        setShowEdit(false)
        getDevelopers()
      }
      else if(res.status=='faild'){
        toast.error(res.message)
      }
      else{
        toast.error('Something Went Error')
      }
    })
    .finally(()=>{
      setEditLoading(false);
    })
    .catch(e=>console.log(e))
  }
  const handleAddDev=()=>{
    if(newDev.developer_name==''){
      toast.warn('Enter Developer Name');
      return
    }
    if(newDev.company_name==''){
      toast.warn('Enter Company Name');
      return
    }
    if(newDev.company_description==''){
      toast.warn('Enter Company Description');
      return
    }

    setAddLoading(true)
    const data_send={
      ...newDev
    }
    console.log(data_send)
    axios.post('https://camp-coding.tech/U_A_D_Walls/admin/add_new_developer.php',data_send)
    .then((res)=>{
      if(res.status=='success'){
        toast.success(res.message)
        getDevelopers()
        setShowAdd(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else {
        toast.error("Something Went Error")
      }
    })
    .finally(()=>{
      setAddLoading(false)
    })
    .catch(e=>{
      console.log(e)
    })
  }
  return (
    <>
      {
        loading?(
          [1, 2, 3].map(x => (
            <Col  xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div style={{overflowX:'auto',marginTop:'78px'}} className='container py-6' >
            <div className='d-flex justify-content-between my-5 mt-6 pt-3'>
              <h5 onClick={()=>{
                setShowAdd(true)
              }} style={{cursor:'pointer'}}>
                <span>Add</span>
                <span>+</span>
              </h5>
              <div>
                <button onClick={()=>{
                  setShowAddByExcel(true)
                }} className='btn btn-success'>Add By Excel</button>
              </div>
            </div>
            <table style={{overflowX:'auto'}} className="table mb-0 table-bordered">
              <thead>
                <tr style={{textAlign:'center'}}>
                  <th>id</th>
                  <th>Project name</th>
                  <th>Project Description</th>
                  <th>Location</th>
                  <th>project_speces</th>
                  <th>selling points</th>
                  <th>facilities</th>
                  <th>payments_delivery</th>
                  <th>data1</th>
                  <th>data2</th>
                  <th>data3</th>
                  <th>data4</th>
                  <th>data5</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  Projects.map((item,index)=>{
                    return(
                      <tr>
                        <td
                          onClick={()=>{
                            navigate('/unites',{state:{projectData:item}})
                          }}
                          style={{
                            color:'blue',
                            textDecoration:'underline',
                            cursor:'pointer'
                          }}
                        >{item.id}</td>
                        <td>{item.project_name}</td>
                        <td>{item.project_description}</td>
                        <td>{item.Location}</td>
                        <td>{item.project_speces}</td>
                        <td>{item.selling_points}</td>
                        <td>{item.facilities}</td>
                        <td>{item.payments_delivery}</td>
                        <td>{item.data1}</td>
                        <td>{item.data2}</td>
                        <td>{item.data3}</td>
                        <td>{item.data4}</td>
                        <td>{item.data5}</td>
                        <td>
                          <MdEditNote onClick={()=>{
                            setRowData(item)
                            setShowEdit(true)
                          }} style={{color:'green',cursor:'pointer',fontSize:'30px'}}/>

                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        )
      }

      <Modal title="Add Projects In Excel Sheet" open={showAddByExcel} onOk={handleAddByExcel} onCancel={()=>{
        setShowAddByExcel(false)
      }}>
        <>
          <input
            style={{
              width:'100%',
              marginTop:'20px'
            }}
            type="file"
            onChange={(e)=>{
              setDevelopersFile(e.target.files[0])
            }}
          />
          {
            addLoading?
            <FidgetSpinner style={{color:'blue'}}/>
            :
            null
          }
        </>

      </Modal>


      <Modal title="Edit Developer Data" open={showEdit} onOk={handleEditDev} onCancel={()=>{
        setShowEdit(false)
      }}>
        <>
          <form action="">
            <div style={{width:'100%'}}>
              <label htmlFor="">Project name	</label>
              <input type="text" placeholder='Developer Name' value={rowData.project_name} onChange={(e)=>{
                setRowData({...rowData,project_name:e.target.value})
              }} />
            </div>
            <div style={{width:'100%'}}>
              <label htmlFor="">project description</label>
              <input type="text" placeholder='Developer Name' value={rowData.project_description} onChange={(e)=>{
                setRowData({...rowData,project_description:e.target.value})
              }} />
            </div>
            <div style={{width:'100%'}}>
              <label htmlFor="">Location</label>
              <input type="text" placeholder='Developer Name' value={rowData.Location} onChange={(e)=>{
                setRowData({...rowData,Location:e.target.value})
              }} />
            </div>



            <div style={{width:'100%'}}>
              <label htmlFor="">project speces</label>
              <input type="text" placeholder='Developer Name' value={rowData.project_speces} onChange={(e)=>{
                setRowData({...rowData,project_speces:e.target.value})
              }} />
            </div>

            <div style={{width:'100%'}}>
              <label htmlFor="">selling points</label>
              <input type="text" placeholder='Developer Name' value={rowData.selling_points} onChange={(e)=>{
                setRowData({...rowData,selling_points:e.target.value})
              }} />
            </div>

            <div style={{width:'100%'}}>
              <label htmlFor="">facilities</label>
              <input type="text" placeholder='Developer Name' value={rowData.facilities} onChange={(e)=>{
                setRowData({...rowData,facilities:e.target.value})
              }} />
            </div>

            <div style={{width:'100%'}}>
              <label htmlFor="">payments delivery</label>
              <input type="text" placeholder='Developer Name' value={rowData.payments_delivery} onChange={(e)=>{
                setRowData({...rowData,payments_delivery:e.target.value})
              }} />
            </div>

            <div style={{width:'100%'}}>
              <label htmlFor="">data1</label>
              <input type="text" placeholder='Developer Name' value={rowData.data1} onChange={(e)=>{
                setRowData({...rowData,data1:e.target.value})
              }} />
            </div>

            <div style={{width:'100%'}}>
              <label htmlFor="">data2</label>
              <input type="text" placeholder='Developer Name' value={rowData.data2} onChange={(e)=>{
                setRowData({...rowData,data2:e.target.value})
              }} />
            </div>

            <div style={{width:'100%'}}>
              <label htmlFor="">data3</label>
              <input type="text" placeholder='Developer Name' value={rowData.data3} onChange={(e)=>{
                setRowData({...rowData,data3:e.target.value})
              }} />
            </div>

            <div style={{width:'100%'}}>
              <label htmlFor="">data4</label>
              <input type="text" placeholder='Developer Name' value={rowData.data4} onChange={(e)=>{
                setRowData({...rowData,data4:e.target.value})
              }} />
            </div>

            <div style={{width:'100%'}}>
              <label htmlFor="">data5</label>
              <input type="text" placeholder='Developer Name' value={rowData.data5} onChange={(e)=>{
                setRowData({...rowData,data5:e.target.value})
              }} />
            </div>


          </form>
          {
            editLoading?
            <FidgetSpinner style={{color:'blue'}}/>
            :
            null
          }
        </>

      </Modal>


    </>
  )
}

export default DevProjects
