import { Table } from 'antd';
import axios from 'axios';
import React from 'react'
import { useLocation } from 'react-router-dom'

const ClientCalls = () => {
  const location=useLocation();
  const {id}=location.state;
  console.log(id)
  const getclientcalls=()=>{
    axios.post("",{
      id:id
    })
    .then((res)=>{
      console.log(res);
      console.log(res.message);
    })
  }
  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
  ];
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];
  return (
    <div className='container' style={{
      marginTop:'80px'
    }}>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  )
}

export default ClientCalls
