import React, { useEffect } from 'react'
import { Button, Card, Col, Modal, Select, Spin } from 'antd';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function SupLocation() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addloading,setaddloading]=useState(false);
  const [Location, setLocation] = useState([])
  const [Category, setCategory] = useState([])
  const [pageloading,setpageloading]=useState(true);
  const [newSuplocation, setnewSuplocation] = useState([])
  const [newLocation, setnewLocation] = useState([])
  const [alllocations,setalllocations]=useState([]);
  const [locationid,setlocationid]=useState("");
  const [suplocdata,setsuplocdata]=useState({
    name:'',
    location_id:'',
  })
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    const data_send={
      ...suplocdata
    }
    setaddloading(true);
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/insert_sub_location.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status==="success"){
        toast.success(res.message);
        getdata();
        getdata2();
      }
      else  if(res.status==="error"){
        toast.error(res.message);
      }
      else {
        toast.error("something is error try again");
      }
    }).finally(()=>{
      setaddloading(false);
    })

  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setnewSuplocation()
    setnewLocation()
  };

  async function getdata(){
    let Location_data=await axios.get(`https://camp-coding.tech/U_A_D_Walls/user/select_locations.php`).catch(err=>{
      console.log(err);
    }).finally(()=>{
      setpageloading(false);
    })
    let category_data=await axios.get(`https://camp-coding.tech/U_A_D_Walls/user/select_category.php`).catch(err=>{
      console.log(err);
    })
    console.log("aaaa",Location_data);
    setLocation(Location_data.message)
    setCategory(category_data.message)
    console.log(Location_data.message[0].location_id);
    setlocationid(Location_data.message[0].location_id);
    setsuplocdata({...suplocdata,location_id:Location_data.message[0].location_id});
    console.log("sfafasfas", category_data.message);
  }
  async function getdata2(){
    let Location_data=await axios.get(`https://camp-coding.tech/U_A_D_Walls/user/select_locations.php`).catch(err=>{
      console.log(err);
    })
    let category_data=await axios.get(`https://camp-coding.tech/U_A_D_Walls/user/select_category.php`).catch(err=>{
      console.log(err);
    })
    // console.log("aaaa",data.massage);
    setalllocations(Location_data.message)
    //setCategory(category_data.data.message)
    //console.log("sfafasfas", category_data.data.message);
  }
  useEffect(()=>{
    getdata()
    getdata2()
  },[])
  return <>
  {
    pageloading?(
      [1, 2, 3].map(x => (
        <Col xs={24} md={12} lg={8} key={x}>
          <Card loading minHeight={200} />
        </Col>
      ))
    ):(
      <div className="container py-5">
      <div className="row py-5 justify-content-between">
        <div className='right-text col-6'>
          <h4>Add SupLocation</h4>
        </div>
        <div className='left-text col-6 text-end'>
          {
            addloading?(
              <Spin/>
            ):(
              <button className='btn btn-outline-info' type="button"
              onClick={showModal}>
                Add SupLocation
              </button>
            )
          }
        </div>

        <Modal title="Add Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered>
            <form>
              <input type='text' className='w-100 form-control mb-3' onChange={e=>{
                setnewSuplocation(e.target.value)
                setsuplocdata({...suplocdata,name:e.target.value});
              }}  value={newSuplocation}  placeholder='Add SupLocation'/>
                <Select
                  value={suplocdata.location_id}
                  style={{
                    width: '100%',
                  }}

                  onChange={(e)=>{
                    setnewLocation(e)
                    setsuplocdata({...suplocdata,location_id:e})
                  }}
                  options={Location.map((item, index) => {

                    return { label: `${item.location_name}`, value: `${item.location_id}` };
                  })}
                />
            </form>
        </Modal>
      </div>
      <div className='row'>
          <div className='col-md-12'>
          <table class="table table-hover">
  <thead>
  <tr>
  <th scope="col">id</th>
  <th scope="col">Location</th>
  <th scope="col">SupLocation Name</th>
  </tr>
  </thead>
  <tbody>
    {
      alllocations.map((item,index)=>{
        return(
          <tr>
            <td>{item.location_id}</td>
            <td>{item.location_name}</td>
            <td>
              {
                item?.sub_location?.map((it,id)=>{
                  return(
                    <span>{(id<item.sub_location.length-1)?it.name+",":it.name}</span>
                  )
                })
              }
            </td>
          </tr>
        )
      })
    }
  </tbody>
  </table>

          </div>

      </div>
    </div>
    )
  }
</>
}

export default SupLocation
