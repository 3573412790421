import { Card, Col, Modal, Select, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Range1 from './range1/Range1';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Range2 from './range2/Range2';
import Range3 from './range3/Range3';
import Slider from '@mui/material/Slider';
import {MdOutlineApartment} from 'react-icons/md'
import {BiBuildingHouse} from 'react-icons/bi'
import {BsHouseSlashFill, BsHouses} from 'react-icons/bs'
const ClientProfile2 = () => {
  const [layoutFile,setLayOutFile]=useState(null);
  const [masterPlanFile,setMasterPlanFile]=useState(null);
  const [paymentPlanFile,setPaymentPlanFile]=useState(null);
  const [feedBack,setFeedBack]=useState('')
  const [followAffterMeeting,setFollowAffterMeeting]=useState('');
  // const [sendWhats,setSendWhats]=useState(false)
  const [sailData,setSailData]=useState({
    developer:'',
    destination:'',
    project:'',
    phase :'',
    type:'',
    num_of_bed:'',
    number_of_baths:'',
    delivery_date:'',
    area:'',
    down_payment:'',
    total_price:'',
    layout:'',
    master_plan:'',
    payment_plan:'',
  });
  const [sendWhats,setSendWhats]=useState(false)
  const [serviceApart,setServiceApart]=useState('residential')
  const [clockTime,setClockTime]=useState('');
  const [prottype,setprottype]=useState("")
  const [bedroomsnumber,setbedroomsnumber]=useState("");
  const [bathroomnumber,setbathroomnumber]=useState("");
  const [delivary,setdelivary]=useState("");
  const [saletype,setsaletype]=useState("");
  const [finishigstatus,setfinishigstatus]=useState("");
  const [install,setinstall]=useState("");
  const [money_from,setmoney_from]=useState("");
  const [money_to,setmoney_to]=useState("");
  const [minsqu,setminsqu]=useState("");
  const [maxsqu,setmaxsqu]=useState("");
  const [downpay,setdownpay]=useState("")
  const [monthinstall,setmonthinstall]=useState("")
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 500000,
      label: '500,000',
    },
    {
      value: 1000000,
      label: '1000,000',
    },
    {
      value: 1500000,
      label: '1,500,000',

    },{
      value: 2000000,
      label: '2,000,000',

    },{
      value: 2500000,
      label: '2,500,000',

    },{
      value: 3000000,
      label: '3,000,000',

    },{
      value: 3500000,
      label: '3,500,000',

    },{
      value: 4000000,
      label: '4,000,000',

    },{
      value: 4500000,
      label: '4,500,000',

    },{
      value: 5000000,
      label: '5,000,000',

    },{
      value: 5500000,
      label: '5,500,000',

    },{
      value: 6000000,
      label: '6,000,000',

    },{
      value: 6500000,
      label: '6,500,000',

    },{
      value: 7000000,
      label: '7,000,000',

    },{
      value: 7500000,
      label: '7,500,000',

    },{
      value: 8000000,
      label: '8,000,000',

    },{
      value: 8500000,
      label: '8,500,000',

    },{
      value: 9000000,
      label: '9,000,000',

    },{
      value: 9500000,
      label: '9,500,000',

    },{
      value: 10000000,
      label: '10,000,000',

    },{
      value: 11000000,
      label: '11,000,000',
    },
    {
      value: 11500000,
      label: '11,500,000',
    },
    {
      value: 12000000,
      label: '12,000,000',
    },
    {
      value: 12500000,
      label: '12,500,000',
    },
    {
      value: 13000000,
      label: '13,000,000',
    },
    {
      value: 13500000,
      label: '13,500,000',
    },
    {
      value: 14000000,
      label: '14,000,000',
    },
    {
      value: 14500000,
      label: '14,500,000',
    },
    {
      value: 15000000,
      label: '15,000,000',
    },
    {
      value: 15500000,
      label: '15,500,000',
    },
    {
      value: 16000000,
      label: '16,000,000',
    },
    {
      value: 16500000,
      label: '16,500,000',
    },
    {
      value: 17000000,
      label: '17,000,000',
    },
    {
      value: 17500000,
      label: '17,500,000',
    },
    {
      value: 18000000,
      label: '18,000,000',
    },
    {
      value: 18500000,
      label: '18,500,000',
    },
    {
      value: 19000000,
      label: '19,000,000',
    },
    {
      value: 19500000,
      label: '19,500,000',
    },
    {
      value: 20000000,
      label: '20,000,000',
    },
  ];
  const marks2 = [
    {
      value: 2.5,
      label: '.5',
    },
    {
      value: 5,
      label: '1',
    },
    {
      value: 7.5,
      label: '1.5',
    },
    {
      value: 10,
      label: '2',
    },
  ];

  function valuetext1(value) {
    return `${value}`;
  }
  function valuetext2(value) {
    return `${value}°C`;
  }
  function valuetext3(value) {
    return `${value}°C`;
  }
  const navigate=useNavigate();
  const authUser=localStorage.getItem("authUser");
  //console.log(JSON.parse(authUser));
  let uid=JSON.parse(authUser);
  //console.log(uid.agent_id)
  const [closingdata,setclosingdata]=useState({
    user_id:uid.agent_id,
    unit_code:'',
    image:'',
    unit_price:'',
    down_pay_ment:'',
    type:'cash',
    insalment_plan:'0',
    unit_price_mark1:'0',
    unit_price_mark2:'0',
    down_pay_mark1:'0',
    down_pay_mark2:'0',
    instal_mark1:'0',
    instal_mark2:'0',
  })
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const showModal3 = () => {
    setIsModalOpen3(true);
  };
  const handleOk3 = () => {
    setIsModalOpen3(false);
    const formdata=new FormData();
    formdata.append("user_id",data.agent_id||data.added_by_agent_id);
    formdata.append("unit_price",`${closingdata.unit_price_mark1}-${closingdata.unit_price_mark2}`);
    formdata.append("unit_code",closingdata.unit_code);
    formdata.append("down_pay_ment",`${closingdata.down_pay_mark1}-${closingdata.down_pay_mark2}`);
    formdata.append("insalment_plan",`${closingdata.instal_mark1}-${closingdata.instal_mark2}`);
    formdata.append("image",closingdata.image);

    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/add_deal_deatils.php",formdata)
    .then((res)=>{
      //console.log(res);
      if(res.status=="success"){
        toast.success(res.message);
      }
      else if(res.status=="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error");
      }
    })
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const [pageloading,setpageloading]=useState(true);
  const location=useLocation();
  const {data}=location.state;
  console.log(data)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [calltype,setcalltype]=useState("");
  const [answertype,setanswertype]=useState("");
  const [date,setdate]=useState("");
  const [meeting,setmeeting]=useState("");
  const [answer_after,setanswer_after]=useState("");
  const [time,settime]=useState("");
  const [link,setlink]=useState("");
  const [note,setnote]=useState("");
  const [not_answer_Content,setnot_answer_Content]=useState("");
  const [call, setCall] = useState([])
  const [call_id, setcall_id] = useState(null)
  const nav =useNavigate()

  const [location_id,setlocation_id]=useState("");
  const [locations,setlocations]=useState([]);

  const [sublocations,setsublocations]=useState([]);
  const [suplocation_id,setsuplocation_id]=useState("");

  const [compound_id,setcompound_id]=useState("");

  const [compoundes,setcompoundes]=useState([]);
  const [coumpound_id,setcoumpound_id]=useState("");
  const [calldata,setcalldata]=useState({});
  const [calldataloading,setcalldataloading]=useState(false);
  async function getdata(){
    let Location_data=await axios.get(`https://camp-coding.tech/U_A_D_Walls/user/select_locations.php`).catch(err=>{
      console.log(err);
    })
    //setLocation(Location_data.message)
    let category_data=await axios.get(`https://camp-coding.tech/U_A_D_Walls/user/select_category.php`).catch(err=>{
      console.log(err);
    })
    let companies=await axios.get("https://camp-coding.tech/U_A_D_Walls/admin/select_company.php").catch(err=>{
      console.log(err);
    })
    //console.log(Location_data.message,"dd")

    setlocations(Location_data.message);
    setlocation_id(Location_data?.message[0]?.location_id)

  }

  const getsublocationsfunc=async()=>{
    let Location_data=await axios.get(`https://camp-coding.tech/U_A_D_Walls/user/select_locations.php`).catch(err=>{
      console.log(err);
    })
    const filtereddata=Location_data.message.filter(item=>item.location_id==location_id)
    setsublocations(filtereddata[0]?.sub_location)
    //console.log(filtereddata[0]?.sub_location)
    setsuplocation_id(filtereddata[0]?.sub_location[0].sub_id)
    //console.log(filtereddata[0]?.sub_location[0].sub_id)
  }
  useEffect(()=>{
    getsublocationsfunc();
  },[location_id]);
useEffect(()=>{
  getdata()
},[])

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if(answer_after=='to_resail'){
      const data_send={
        ...sailData,
        agent_id:authUser.agent_id,
      }
      // console.log(data_send,"ew")
      const formData1=new FormData();
      formData1.append('image',layoutFile);
      axios.post("https://camp-coding.tech/U_A_D_Walls/admin/insert_resial_data.php",JSON.stringify(data_send))
      .then((res)=>{

      }).catch(e=>{
        console.log(e)
      })
    }
    else {
      setIsModalOpen(false);
      const data_send={
        client_id:data.client_id,
        agent_id:data.agent_id||data.added_by_agent_id,
        response:calltype,
        date_to_follow:date,
        send_whats:sendWhats?'send':'not Send',
        feed_back:feedBack,
        follow_up_after_meeting:followAffterMeeting,
        answer_details:calltype=="answer"?answertype:not_answer_Content,
      }
      if(calltype=="answer" &&answertype=="interested"){
        data_send['user_offer']=link;
        data_send['note']='note';
        data_send['time']=time;
        data_send['service_apart']=serviceApart;
        data_send['bedrooms']=bedroomsnumber;
        data_send['bathroom']=bathroomnumber;
        data_send['min_price']=money_from;
        data_send['max_price']=money_to;
        data_send['max_square_meter']=maxsqu;
        data_send['min_square_meter']=minsqu;
        data_send['delivery_date']=delivary;
        data_send['sale_type']=saletype;
        data_send['finishing']=finishigstatus;
        data_send['down_payment_plan']=downpay;
        data_send['monthly_payment_plan']=monthinstall;
        data_send['installment_year']=install;
        data_send['location_id']=location_id;
        data_send['sub_location_id']=suplocation_id;
        data_send['compound_id']=compound_id;
        data_send['property_type']=prottype;
      }
      //console.log(JSON.stringify(data_send))
      console.log(data_send)
      // return

      axios.post("https://camp-coding.tech/U_A_D_Walls/admin/calling_client.php",JSON.stringify(data_send))
      .then((res)=>{
        console.log(res)
        setcalltype("");
        setlink("");
        setbedroomsnumber("");
        setbathroomnumber("");
        setmoney_from("");
        setmoney_to("");
        setmaxsqu("");
        setminsqu("");
        setmaxsqu("");
        setdelivary("");
        setsaletype("");
        setfinishigstatus("");
        setdownpay("");
        setmonthinstall("");
        setinstall("");
        setprottype("");
        setanswer_after("");
        if(res.status=="success"){
          toast.success(res.message);
          geteployees();
        }
        else if(res.status=="error"){
          toast.error(res.message);
        }
        else{
          toast.error("something is error try again")
        }
      })
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);

  };



  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
    const data_send={
      client_id:data.client_id,
      agent_id:data.agent_id,
      date:meeting,
      clock:clockTime,
      call_id:call_id
    }
    //console.log('data',data_send);
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/insert_meating.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status=="success"){
        toast.success(res.message);
        setmeeting('')
        setClockTime('')
      }
      else if(res.status=="error"){
        toast.error(res.message);

      }
      else{
        toast.error("something is error try again")

      }
    }).finally(()=>{

    })
  };



  async function geteployees(){
    //console.log(data.client_id,data.added_by_agent_id||data.agent_id)
    const respo=await axios.post("https://camp-coding.tech/U_A_D_Walls/admin/select_calling_data.php",{
      client_id:data.client_id,
      agent_id:data.added_by_agent_id||data.agent_id,
    }).finally(()=>{
      setpageloading(false);
    })
    // console.log(respo.message)
    // console.log(respo)
    setCall(respo.message)
  }

  const getcompounds=()=>{
    axios.get("https://camp-coding.tech/U_A_D_Walls/admin/select_compounds.php")
    .then((res)=>{
      //console.log(res)
      setcompoundes(res?.message);
      setcoumpound_id(res?.message[0]?.compound_id)
    })
  }

  const handlegetrequest=(id)=>{
    setcalldataloading(true);
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/select_requests.php",{
      call_id:id
    })
    .then((res)=>{
      console.log(res);
      setcalldata(res.message[0]);
    }).catch((err)=>console.log(err))
    .finally(()=>{
      setcalldataloading(false);
    })
  }

  useEffect(()=>{
    geteployees();
    getcompounds()
  },[])


  const [isModalOpencall, setIsModalOpencall] = useState(false);
  const showModalcall = () => {
    setIsModalOpencall(true);
  };
  const handleOkcall = () => {
    setIsModalOpencall(false);
  };
  const handleCancelcall = () => {
    setIsModalOpencall(false);
  };

  const rendercalldata=[
    {
      title: 'bathrooms',
      dataIndex: 'bathroom',
      key: 'bathroom',
    },
    {
      title: 'bedrooms',
      dataIndex: 'bedrooms',
      key: 'bedrooms',
    },
    {
      title: 'compound name',
      dataIndex: 'compound_name',
      key: 'compound_name',
    },
    {
      title: 'delivery date',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
    },
    {
      title: 'down payment',
      dataIndex: 'down_payment_plan',
      key: 'down_payment_plan',
    },
    {
      title: 'finishing',
      dataIndex: 'finishing',
      key: 'finishing',
    },
    {
      title: 'installment year',
      dataIndex: 'installment_year',
      key: 'installment_year',
    },
    {
      title: 'max price',
      dataIndex: 'max_price',
      key: 'max_price',
    },
    {
      title: 'min price',
      dataIndex: 'min_price',
      key: 'min_price',
    },
    {
      title: 'max square',
      dataIndex: 'max_square_meter',
      key: 'max_square_meter',
    },
    {
      title: 'min square',
      dataIndex: 'min_square_meter',
      key: 'min_square_meter',
    },
    {
      title: 'monthly payment',
      dataIndex: 'monthly_payment_plan',
      key: 'monthly_payment_plan',
    },
    {
      title: 'property type',
      dataIndex: 'property_type',
      key: 'property_type',
    },
    {
      title: 'sale type',
      dataIndex: 'sale_type',
      key: 'sale_type',
    },
    // {
    //   title: 'location name',
    //   key: 'location',
    //   render:(_,record)=>(
    //     <Space>
    //       <h6>{record.location_data.location_name}</h6>
    //     </Space>
    //   )
    // },
    {
      title: 'sup location name',
      dataIndex: 'sub_location',
      key: 'sub_location',
    },
  ]

  return (
    <>
      {
        pageloading?(
          [1, 2, 3].map(x => (
            <Col xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div style={{
            marginTop:'30px'
          }} className='py-5 container'>
            <table className="table mb-0 table-bordered">

            <thead>
              <tr style={{textAlign:'center'}}>
                <th>Id</th>
                <th>Name</th>
                <th>Address</th>
                {/* <th>called</th> */}
                <th>Job</th>
                <th>Phone</th>
                <th>Comments</th>
                <th>dealings</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{textAlign:'center'}}>
                <th>{data.client_id}</th>
                <th>{data.client_name||data.name}</th>
                <th>{data.client_address||data.address}</th>
                {/* <th>{data.client_called}</th> */}
                <th>{data.client_job||data.job}</th>
                <th>{data.client_phone||data.phone}</th>
                <th>
                  <button style={{fontSize:'19px'}}
                  onClick={()=>{
                    nav('/review',{state:{data:data}})
                  }} type="button" className="btn btn-sm btn-link bg-primary text-white  text-dark text-decoration-none">comments</button>
                </th>
                <th>
                  <button
                  style={{fontSize:'19px'}}
                  className="btn btn-sm btn-link bg-primary text-white  text-dark text-decoration-none"
                    onClick={()=>{
                      navigate("/clientcalls",{state:{id:data.client_id}})
                    }}
                  >dealings</button>
                </th>
              </tr>
            </tbody>
            </table>
            <div style={{
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between',
              width:'100%'
            }}>
                    <h4>add new call</h4>
            <AiOutlinePlus
              onClick={showModal}
              style={{
                color:'green',
                fontSize:'20px',
                cursor:'pointer',
              }}
            />
            </div>
            <Modal title="add new call" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {/* <div onClick={()=>{
              setSendWhats(!sendWhats)
            }} style={{margin:'20px 0px',width:'fit-content',display:'flex',alignItems:'center',gap:'3px',cursor:'pointer'}}>
              <input style={{width:'20px',height:'20px',display:'block'}} type="checkbox" checked={sendWhats} onChange={()=>{
              }} />
              <label style={{margin:'0px'}} htmlFor="">Send WhatsApp</label>
            </div> */}
            <div onClick={()=>{
              setSendWhats(!sendWhats)
            }} style={{margin:'20px 0px',width:'fit-content',display:'flex',alignItems:'center',gap:'3px',cursor:'pointer'}}>
              <input style={{width:'20px',height:'20px',display:'block'}} type="checkbox" checked={sendWhats} onChange={()=>{
              }} />
              <label style={{margin:'0px'}} htmlFor="">Send WhatsApp</label>
            </div>
            <div style={{width:'100%',margin:'10px 0px'}}>
                  <label htmlFor="">FeedBack</label>
                  <input className='form-control' type="text" onChange={(e)=>{
                    setFeedBack(e.target.value);
                  }} placeholder='FeedBack' />
                </div>
              <div style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between',
                flexWrap:'wrap'
              }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                    checked={calltype=="answer"}
                      onClick={() => {
                        setcalltype("answer")
                      }}
                      style={{ display: "block" }}
                      type="radio"
                      id="answer"
                      name="fav_language"
                      value="answer"
                    />
                    <label
                    onClick={()=>{
                      setcalltype("answer")
                    }}
                      htmlFor="answer"
                      style={{
                      }}
                    >
                      answer
                    </label>
                  </div>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <input
                    checked={calltype=="not_answer"}
                      onClick={(e) => {
                        setcalltype("not_answer")
                      }}
                      style={{ display: "block" }}
                      type="radio"
                      id="not_answer"
                      name="fav_language"
                      value="company"
                    />
                    <label onClick={()=>{
                        setcalltype("not_answer")
                    }} style={{}} htmlFor="not_answer">
                      not answer
                    </label>
                  </div>
              </div>
              {
                calltype=="answer"?(
                  <>
                    <Select
                    value={answertype}
                      style={{ width:'100%' }}
                      placeholder="enter answer"
                      //onChange={handleChange}
                      onChange={(e)=>{
                        //console.log(e)
                        setanswertype(e);
                      }}
                      options={[
                        { value: 'interested', label: 'interested' },
                        { value: 'not interested', label: 'not interested' },
                        { value: 'interested but not_now', label: 'interested but not now' },
                        { value: 'low_budjet', label: 'low budjet' },
                        { value: 'pending', label: 'pending' },
                      ]}
                    />
                    {
                      answertype=="interested_but_not_now"?(
                        <>
                          <input
                            style={{
                              width:'100%',
                              marginTop:'20px'
                            }}
                            type="date"
                            onChange={(e)=>{
                              setdate(e.target.value)
                            }}
                          />
                        </>
                      )
                      :
                      answertype=="not_interested"?
                      (
                        null
                      )
                      :
                      answertype=="interested"?
                      (
                        <div style={{
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'space-between',
                          marginBottom:'10px',
                          marginTop:'10px'
                        }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                            }}
                          >
                            <input
                            checked={answer_after=="continue"}
                              onClick={() => {
                                setanswer_after("continue")
                              }}
                              style={{ display: "block" }}
                              type="radio"
                              id="continue"
                              name="fav_language2"
                              value="continue"
                            />
                            <label
                              htmlFor="continue"
                              onClick={()=>{
                                setanswer_after("continue")
                              }}
                              style={{
                                display:'flex',
                                alignItems:'center',
                              }}
                            >
                              continue
                            </label>
                          </div>



                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                            }}
                          >
                            <input
                            checked={answer_after=="to_resail"}
                              onClick={() => {
                                setanswer_after("to_resail")
                              }}
                              style={{ display: "block" }}
                              type="radio"
                              id="to_resail"
                              name="fav_language2"
                              value="to_resail"
                            />
                            <label
                              htmlFor="to_resail"
                              onClick={()=>{
                                setanswer_after("to_resail")
                              }}
                              style={{
                                display:'flex',
                                alignItems:'center',
                              }}
                            >
                              Client Need To Resale
                            </label>
                          </div>



                          <div style={{display:'flex',alignItems:'center'}}>
                            <input
                            checked={answer_after=="reschadural"}
                              onClick={(e) => {
                                setlink("");
                                setnote("")
                                setanswer_after("reschadural")
                              }}
                              style={{ display: "block" }}
                              type="radio"
                              id="reschadural"
                              name="fav_language2"
                              value="reschadural"
                            />
                            <label onClick={()=>{
                                setanswer_after("reschadural")
                            }} style={{ }} htmlFor="reschadural">
                              reschadural
                            </label>
                          </div>
                        </div>
                      ):(
                        null
                      )
                    }
                          {
                            answer_after=="continue"?(
                              <div>
                                <div className='time_date'>
                                  <div>
                                    follow up call
                                  </div>
                                  <div>
                                    <input style={{
                                      width:'100%',
                                      height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px',
                                      marginBottom:'10px'

                                    }} onChange={(e)=>{
                                      settime(e.target.value)
                                    }} type="time"/>
                                    <input style={{
                                      width:'100%',
                                      height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px',
                                      marginBottom:'10px'
                                    }} onChange={(e)=>{
                                      setdate(e.target.value)
                                    }} type="date"/>
                                  </div>
                                </div>
                                <div style={{margin:'10px 0px'}}>
                                  <label htmlFor="">Follow Up After Meeting</label>
                                  <input className='form-control' type="time" name="" onChange={(e)=>{
                                    setFollowAffterMeeting(e.target.value);
                                  }} id="" />
                                </div>
                                <input
                                  value={link}
                                style={{
                                      width:'100%',
                                      height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px',
                                      marginBottom:'10px'
                                    }} type="text"
                                    onChange={(e)=>{
                                      setlink(e.target.value)
                                    }}
                                    placeholder='enter link of unit'/>
                                {/* <textarea
                                  onChange={(e)=>{
                                    setnote(e.target.value)
                                  }}
                                  placeholder='enter details'
                                  style={{
                                  resize:'none',
                                  width:'100%',
                                  height:'50px',
                                  border:'1px solid #ccc',
                                  padding:'10px',
                                  borderRadius:'10px'
                                }}></textarea> */}
                                <div className='' style={{margin:'30px 0px'}}>
                                  <label htmlFor="">serviced apartments</label>
                                  <Select
                                    style={{ width:'100%' }}
                                    placeholder="enter answer"
                                    //onChange={handleChange}
                                    value={serviceApart}
                                    onChange={(e)=>{
                                      setServiceApart(e);
                                    }}
                                    options={[
                                      { value: 'residential', label: 'Residential' },
                                      { value: 'costal', label: 'Costal' },
                                      { value: 'commercial', label: 'Commercial' },
                                      { value: 'admin', label: 'Admin' },
                                      { value: 'medical', label: 'Medical' },
                                    ]}
                                  />
                                </div>
                                <div className='property_type_div' >
                                  <h5>property type</h5>
                                  <div className='property_types'>
                                    <div className="property_type"
                                      onClick={()=>{
                                        setprottype("apartment")
                                      }}
                                    >
                                      <div className={`${prottype=="apartment"?'active':''}`} style={{
                                        border:'1px solid #ccc'
                                      }}>
                                      <MdOutlineApartment/>
                                      </div>
                                      <h6>apartment</h6>
                                    </div>
                                    <div className="property_type"
                                    onClick={()=>{
                                      setprottype("vila")
                                    }}
                                    >
                                      <div className={`${prottype=="vila"?'active':''}`} style={{
                                        border:'1px solid #ccc'
                                      }}>
                                      <img style={{width:'30px'}} src={require("../../../assets/images/vila.png")} alt="" />
                                      </div>
                                      <h6>vila</h6>
                                    </div>
                                    <div className="property_type"
                                    onClick={()=>{
                                      setprottype("twinhouse")
                                    }}
                                    >
                                      <div className={`${prottype=="twinhouse"?'active':''}`} style={{
                                        border:'1px solid #ccc'
                                      }}>
                                      <BiBuildingHouse/>
                                      </div>
                                      <h6>twinhouse</h6>
                                    </div>
                                    <div className="property_type"
                                      onClick={()=>{
                                        setprottype("duplex")
                                      }}
                                    >
                                      <div className={`${prottype=="duplex"?'active':''}`} style={{
                                        border:'1px solid #ccc'
                                      }}>
                                      <BsHouseSlashFill/>
                                      </div>
                                      <h6>duplex</h6>
                                    </div>
                                    <div className="property_type"
                                    onClick={()=>{
                                      setprottype("townhouse")
                                    }}
                                    >
                                      <div className={`${prottype=="townhouse"?'active':''}`} style={{
                                        border:'1px solid #ccc'
                                      }}>
                                      <BsHouses/>
                                      </div>
                                      <h6>townhouse</h6>
                                    </div>
                                  </div>
                                </div>
                                <div style={{
                                  margin:'20px 0px'
                                }}>
                                  <h6>bedrooms number</h6>
                                <div className="bedrooms_numbers">
                                  <div onClick={()=>{setbedroomsnumber("all")}} className={bedroomsnumber=="all"?'bedrooms_number active':'bedrooms_number'}>all</div>
                                  <div className={`${bedroomsnumber*1>=1? "bedrooms_number active":"bedrooms_number"}`}

                                  onClick={()=>{
                                    setbedroomsnumber("1")
                                  }}>1</div>
                                  <div className={`${bedroomsnumber*1>=2? "bedrooms_number active":"bedrooms_number"}`}
                                  onClick={()=>{
                                    setbedroomsnumber("2")
                                  }}
                                  >2</div>
                                  <div className={`${bedroomsnumber*1>=3? "bedrooms_number active":"bedrooms_number"}`}
                                  onClick={()=>{
                                    setbedroomsnumber("3")
                                  }}
                                  >3</div>
                                  <div className={`${bedroomsnumber*1>=4? "bedrooms_number active":"bedrooms_number"}`}
                                  onClick={()=>{
                                    setbedroomsnumber("4")
                                  }}
                                  >4</div>
                                  <div className={`${bedroomsnumber*1>=5? "bedrooms_number active":"bedrooms_number"}`}
                                  onClick={()=>{
                                    setbedroomsnumber("5")
                                  }}
                                  >5</div>
                                  <div className={`${bedroomsnumber*1>=6? "bedrooms_number active":"bedrooms_number"}`}
                                  onClick={()=>{
                                    setbedroomsnumber("6")
                                  }}
                                  >6+</div>
                                </div>
                                </div>
                                <div>
                                  <h6 style={{textTransform:'capitalize'}}>bathrooms number</h6>
                                <div className="bathrooms_numbers">
                                  <div onClick={()=>{setbathroomnumber("all")}} className={bathroomnumber=="all"?"bathroom_number active":"bathroom_number"}>
                                    all
                                  </div>
                                  <div onClick={()=>{
                                    setbathroomnumber("1")
                                  }} className={`${bathroomnumber*1>=1?"bathroom_number active":"bathroom_number"}`}>
                                    1
                                  </div>
                                  <div onClick={()=>{
                                    setbathroomnumber("2")
                                  }} className={`${bathroomnumber*1>=2?"bathroom_number active":"bathroom_number"}`}>
                                    2
                                  </div>
                                  <div onClick={()=>{
                                    setbathroomnumber("3")
                                  }} className={`${bathroomnumber*1>=3?"bathroom_number active":"bathroom_number"}`}>
                                    3
                                  </div>
                                </div>
                                </div>
                                <div>
                                <div style={{
                                  width:'100%',
                                  display:'flex',
                                  alignItems:'center',
                                  justifyContent:'space-between'
                                }}>
                                  <p style={{
                                    textAlign:'center',
                                    width:'50%',
                                    margin:'0px',
                                    fontSize:'19px'
                                  }}>
                                    from
                                  </p>
                                  <p
                                  style={{
                                    textAlign:'center',
                                    width:'50%',
                                    margin:'0px',
                                    fontSize:'19px'
                                  }}
                                  >
                                    to
                                  </p>
                                </div>
                    <div style={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between'
                  }}>
                    <Select
                    showSearch
                    onChange={(e)=>{
                      setmoney_from(e)
                    }}
                    value={money_from}
                      style={{
                        width: '50%',
                      }}
                        options={marks.map((item, index) => {
                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                    />
                    <Select
                    showSearch
                    onChange={(e)=>{
                      setmoney_to(e)
                    }}
                    value={money_to}
                      style={{
                        width: '50%',
                      }}
                        options={marks.map((item, index) => {

                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                      />
                  </div>
                                </div>
                                <div className='square_metter'>
                                  <h5>square meter <span>(m2)</span></h5>
                                <div className="min_max_square">
                                <div className='min_square'>
                                    <input value={minsqu} onChange={(e)=>{setminsqu(e.target.value)}} type="text" placeholder='min 42'/>
                                    <span>(m2)</span>
                                  </div>
                                  <div className="max_square">
                                  <input value={maxsqu} onChange={(e)=>{setmaxsqu(e.target.value)}} type="text" placeholder='max 1287'/>
                                    <span>(m2)</span>
                                  </div>
                                </div>
                                </div>
                                <div className="delivary_date">
                                  <h6 style={{textTransform:'capitalize'}}>Delivary Date</h6>
                                  <div className="delivaries">
                                    <div onClick={()=>{setdelivary("move_now")}} className={delivary=="move_now"?"delivary active":"delivary"}>Move Now</div>
                                    <div onClick={()=>{setdelivary("2024")}} className={delivary=="2024"?"delivary active":"delivary"}>2024</div>
                                    <div onClick={()=>{setdelivary("2025")}} className={delivary=="2025"?"delivary active":"delivary"}>2025</div>
                                    <div onClick={()=>{setdelivary("2026")}} className={delivary=="2026"?"delivary active":"delivary"}>2026</div>
                                    <div onClick={()=>{setdelivary("2027")}} className={delivary=="2027"?"delivary active":"delivary"}>2027</div>
                                  </div>
                                </div>
                                <div className="sale_type_div">
                                  <h6>sale type</h6>
                                  <div className='sales_type'>
                                    <div  onClick={()=>{
                                      setsaletype("developer_sale")
                                    }} className={`${saletype=="developer_sale"?"sale_type active":"sale_type"}`}>Primary</div>
                                    <div onClick={()=>{
                                      setsaletype("resale")
                                    }} className={`${saletype=="resale"?"sale_type active":"sale_type"}`}>resale</div>
                                    {/* <div className={`${saletype=="nawy_now"?"sale_type active":"sale_type"}`}
                                    onClick={()=>{
                                      setsaletype("nawy_now")
                                    }}
                                    >nawy now</div> */}
                                  </div>
                                </div>
                                <div className="finishing_div">
                                  <h6>finishing</h6>
                                  <div className="finishings">
                                    <div onClick={()=>{
                                      setfinishigstatus("all");
                                    }} className={finishigstatus=="all"?"finishing active":"finishing"}>all</div>
                                    <div onClick={()=>{setfinishigstatus("unfinished")}} className={finishigstatus=="unfinished"?"finishing active":"finishing"}>unfinished</div>
                                    <div onClick={()=>{setfinishigstatus("semi_finished")}} className={finishigstatus=="semi_finished"?"finishing active":"finishing"}>semi-finished</div>
                                    <div onClick={()=>{setfinishigstatus("finished")}} className={`${finishigstatus=="finished"?"finishing active":"finishing"}`}>finished</div>
                                  </div>
                                </div>
                                <div className="payment_paln_div">
                                  <h6 style={{textTransform:"capitalize"}}>payment plan</h6>
                                  <div className="pay_methods">
                                  <div className="down_payment">
                                    <h5 style={{textAlign:'center'}}>Down Payment</h5>
                                    <input value={downpay} onChange={(e)=>{setdownpay(e.target.value)}} type="text" placeholder='Up to'/>
                                  </div>
                                  <div className="monthly_installment">
                                    <h5 style={{textAlign:'center',width:'100%'}}>monthly installment</h5>
                                    <input value={monthinstall} onChange={(e)=>{setmonthinstall(e.target.value)}} type="text" placeholder='Up to'/>
                                    <span>EGP</span>
                                  </div>
                                  </div>
                                </div>
                                <div className="installment_years">
                                  <h5>installment years</h5>
                                  <div className="installments">
                                    <div onClick={()=>{setinstall("all")}} className={install=="all"?"install active":"install"}>all</div>
                                    <div onClick={()=>{setinstall("1")}} className={install.replace('+','')*1>=1?"install active":"install"}>1</div>
                                    <div onClick={()=>{setinstall("2")}} className={install.replace('+','')*1>=2?"install active":"install"}>2</div>
                                    <div onClick={()=>{setinstall("3")}} className={install.replace('+','')*1>=3?"install active":"install"}>3</div>
                                    <div onClick={()=>{setinstall("4")}} className={install.replace('+','')*1>=4?"install active":"install"}>4</div>
                                    <div onClick={()=>{setinstall("5")}} className={install.replace('+','')*1>=5?"install active":"install"}>5</div>
                                    <div onClick={()=>{setinstall("6+")}} className={install.replace('+','')*1>=6?"install active":"install"}>6+</div>
                                  </div>
                                </div>
                                <div className="select_area">
                                  <h5 style={{textTransform:'capitalize'}}>area</h5>
                                <div className='sullocation'>
                                <Select
                                style={{width:'48%'}}

                                    value={location_id}
                                    className=" nice-select"
                                    placeholder="select location"
                                    onChange={(e)=>{
                                      let data=locations.filter(item=>item.location_id==e);

                                      setlocation_id(e);
                                      // setbuildingdata({...buildingdata,sub_location_id:data[0]?.sub_location[0]?.sub_id});
                                      setlocation_id(e);
                                      //setbuildingdata({...buildingdata,location_id:e});
                                    }}  id=""
                                    options={locations.map((item,index)=>{
                                      return {label:`${item.location_name}`, value:`${item.location_id}`};
                                    })
                                  }
                                  />
                                  <Select
                                    style={{width:'48%'}}
                                    value={suplocation_id}
                                    className=" nice-select"
                                    placeholder="select location"
                                    onChange={(e)=>{
                                      //console.log(e)
                                      setsuplocation_id(e);
                                    }}  id=""
                                  // options={Location?.find(item=>item.location_id==buildingdata.location_id)?.sub_location?.map(item=>{
                                  //   return {label:`${item.name}`, value:`${item.sub_id}`};
                                  // })}
                                  options={sublocations?.map((item,index)=>{
                                    return {label:`${item.name}`, value:`${item.sub_id}`};
                                    })}
                                  />
                                </div>
                                </div>
                                <div className="select_compund">
                                  <h6 style={{textTransform:'capitalize'}}>compound</h6>

                                  <Select

                    showSearch
                    onChange={(e)=>{
                      setcompound_id(e);
                    }}
                    value={compound_id}
                      style={{
                        width: '100%',
                      }}
                        options={compoundes.map((item, index) => {
                          return { label: `${item.compound_name}`, value: `${item.compound_id}` };
                        })}
                    />

                                  {/* <div className="add_compound">
                                    add compound <span>+</span>
                                  </div> */}
                                </div>
                              </div>
                            ):answer_after=="reschadural"?(
                              <div style={{
                                width:'100%'
                              }}>
                                <input style={{
                                      width:'100%',
                                      height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px',
                                      marginBottom:'10px'
                                    }}type="time" onChange={(e)=>{
                                  settime(e.target.value)
                                }}/>
                                <input style={{
                                      width:'100%',
                                      height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px'
                                    }} type="date" onChange={(e)=>{
                                  setdate(e.target.value);
                                }}/>
                              </div>
                            ):
                            answer_after=='to_resail'?
                            (
                              <div className='resile_div'>
                                <div className="row">
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Developer:</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,developer:e.target.value})
                                    }} value={sailData?.developer} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Destination :</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,destination:e.target.value})
                                    }} value={sailData?.destination} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Project :</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,project:e.target.value})
                                    }} value={sailData?.project} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Type :</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,type:e.target.value})
                                    }} value={sailData?.type} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Number Of Beds :</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,num_of_bed:e.target.value})
                                    }} value={sailData?.num_of_bed} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Number Of Baths :</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,number_of_baths:e.target.value})
                                    }} value={sailData?.number_of_baths} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Delivery date  :</label>
                                    <input type="date" onChange={(e)=>{
                                      setSailData({...sailData,delivery_date:e.target.value})
                                    }} value={sailData?.delivery_date} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Area  :</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,area:e.target.value})
                                    }} value={sailData?.area} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Down Payment  :</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,down_payment:e.target.value})
                                    }} value={sailData?.down_payment} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Total Price  :</label>
                                    <input type="text" onChange={(e)=>{
                                      setSailData({...sailData,total_price:e.target.value})
                                    }} value={sailData?.total_price} />
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Layout  :</label>
                                    <input type="file" onChange={(e)=>{
                                      setLayOutFile(e.target.files[0])
                                    }}/>
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Master Plan  :</label>
                                    <input type="file" onChange={(e)=>{
                                      setMasterPlanFile(e.target.files[0])
                                    }}/>
                                  </div>
                                  <div className='col-lg-6'>
                                    <label htmlFor="">Payment Plan  :</label>
                                    <input type="file" onChange={(e)=>{
                                      setPaymentPlanFile(e.target.files[0])
                                    }} />
                                  </div>
                                </div>
                                {/* here */}
                              </div>
                            )
                            :
                            null
                          }
                  </>
                ):
                calltype=="not_answer"?

                (
                  <>
                    <Select
                    placeholder="enter resone of not answer"
                        //defaultValue="lucy"
                        style={{ width: "100%" }}
                        onChange={(e)=>{
                          setnot_answer_Content(e);
                        }}
                        options={[
                          { value: 'phone_closed', label: 'phone closed' },
                          { value: 'out_of_service', label: 'out of service' },
                          { value: 'wrong_number', label: 'wrong number' },
                          { value: 'ring_without_response', label: 'ring without response'},
                        ]}
                    />
                  </>
                )
                :
                (
                  null
                )
              }
            </Modal>


      <table class="table">
        <thead className='text-center'>
          <tr>
            <th>number</th>
                <th>Call id</th>
                {/* <th>Agent Name</th> */}
                <th>Response</th>
                <th>Answer Details</th>
                <th>Date To Follow</th>
                <th>User Offer</th>
                <th>Feed Back</th>
                <th>Meeting</th>
          </tr>
        </thead>
        <tbody  className='text-center'>
          {call&&Array.isArray(call)&&call?.map((items ,index)=>{
            return <tr key={index}>
              <td>{index+1}</td>
              <td>
                  {items.call_id}
              </td>
              {/* <td>
                  {items.agent_name}
              </td> */}
              <td>
                  {items.response}
              </td>
              <td>
                  {items.answer_details}
              </td>
              <td>
                  {items.date_to_follow}
              </td>

              <td>
                  {items.user_offer}
              </td>
              <td>{items.feed_back}</td>
              <td>
                  <button className='btn btn-outline-danger'  onClick={()=>{
                    showModal2()
                    setcall_id(items.call_id)
                  }} >Meeting</button>
              </td>
              <td>
                  <button className='btn btn-outline-danger'  onClick={()=>{
                    showModal3()
                    //setcall_id(items.call_id)
                  }} >Done deal</button>
              </td>
              {
                items.answer_details=="interested"?(
                  <td>
                <button onClick={()=>{
                  handlegetrequest(items.call_id);
                  showModalcall()
                }} className='btn btn-outline-danger'>requests</button>
              </td>
                ):(null)
              }
                {/* <Table dataSource={[calldata]} columns={rendercalldata}/> */}



            </tr>

          })}

        </tbody>
      </table>

          </div>
        )
      }
              <Modal title="Done deal" open={isModalOpen3} onOk={handleOk3} onCancel={handleCancel3}>
                <form style={{alignItems:'start'}}>
                  <input
                    onChange={(e)=>{
                      setclosingdata({...closingdata,unit_code:e.target.value})
                    }}
                    type="text" placeholder='enter unit code'/>
                  <h4 style={{textAlign:'left'}}>contract image</h4>
                  <input
                  onChange={(e)=>{
                    setclosingdata({...closingdata,image:e.target.files[0]})
                  }}
                  type="file" name="" id="" />
                  {/* <div style={{
                    width:'100%',
                    height:'40px',
                    marginBottom:'20px'
                  }}>
<Range1
                    _changevalue={ps=>{
                      setclosingdata({...closingdata,
                      unit_price:`${ps[0]} - ${ps[1]}`
                      })
                      //console.log("jjfs")
                      //setvalue(ps);
                      //console.log("lkwkeklwelk",ps);
                    }}
                  />

                  </div> */}

                  {/* <input
                  onChange={(e)=>{
                    setclosingdata({...closingdata,unit_price:e.target.value})
                  }}
                  type="text" placeholder='enter unit total price'/> */}



                  {/* <input
                  onChange={(e)=>{
                    setclosingdata({...closingdata,down_pay_ment:e.target.value})
                  }}
                  type="text" placeholder='value of down payment'/> */}
                  <div style={{
                    width:'100%'
                  }}>
                    <h3 style={{textTransform:'capitalize'}}>unit price</h3>
                    <div style={{
                      width:'100%',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'space-between'
                    }}>
                      <p style={{
                        textAlign:'center',
                        width:'50%',
                        margin:'0px',
                        fontSize:'19px'
                      }}>
                        from
                      </p>
                      <p
                      style={{
                        textAlign:'center',
                        width:'50%',
                        margin:'0px',
                        fontSize:'19px'
                      }}
                      >
                        to
                      </p>
                    </div>
                  </div>
                  <div style={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between'
                  }}>
                    <Select
                    showSearch
                    onChange={(e)=>{
                      setclosingdata({...closingdata,unit_price_mark1:e})
                    }}
                    value={closingdata.unit_price_mark1}
                      style={{
                        width: '50%',
                      }}
                        options={marks.map((item, index) => {
                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                    />
                    <Select
                    showSearch
                    onChange={(e)=>{
                      setclosingdata({...closingdata,unit_price_mark2:e})
                    }}
                    value={closingdata.unit_price_mark2}
                      style={{
                        width: '50%',
                      }}
                        options={marks.map((item, index) => {

                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                      />

                    {/* <Slider
                      track="inverted"
                      aria-labelledby="track-inverted-slider"
                      getAriaValueText={valuetext3}
                      defaultValue={30}
                      marks={marks}
                      min={0}
                      max={105}
                    /> */}
                  {/* <Range2

                    _changevalue={ps=>{
                      setclosingdata({...closingdata,
                        down_pay_ment:`${ps[0]} - ${ps[1]}`
                        })
                        //console.log("jjfs")
                        //setvalue(ps);
                        //console.log("lkwkeklwelk",ps);
                      }}

                    /> */}
                  </div>
                  <div style={{
                    width:'100%'
                  }}>
                    <h3 style={{textTransform:'capitalize'}}>Down Payment</h3>
                    <div style={{
                      width:'100%',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'space-between'
                    }}>
                      <p style={{
                        textAlign:'center',
                        width:'50%',
                        margin:'0px',
                        fontSize:'19px'
                      }}>
                        from
                      </p>
                      <p
                      style={{
                        textAlign:'center',
                        width:'50%',
                        margin:'0px',
                        fontSize:'19px'
                      }}
                      >
                        to
                      </p>
                    </div>
                  </div>
                  <div style={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between'
                  }}>
                    <Select
                    showSearch
                    onChange={(e)=>{
                      setclosingdata({...closingdata,down_pay_mark1:e})
                    }}
                    value={closingdata.down_pay_mark1}
                      style={{
                        width: '50%',
                      }}
                        options={marks.map((item, index) => {
                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                    />
                    <Select
                    showSearch
                    onChange={(e)=>{
                      setclosingdata({...closingdata,down_pay_mark2:e})
                    }}
                    value={closingdata.down_pay_mark2}
                      style={{
                        width: '50%',
                      }}
                        options={marks.map((item, index) => {

                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                      />


                  </div>
                  <select


                    onChange={(e)=>{
                      setclosingdata({...closingdata,type:e.target.value})
                    }}
                    value={closingdata.type}
                    style={{
                      width:'100%',
                      height:'30px'
                    }}>
                    <option value="cash">cash</option>
                    <option value="instalment">instalment</option>
                  </select>
                  {
                    closingdata.type=="instalment"?(
                      // <input onChange={(e)=>{
                      //   setclosingdata({...closingdata,insalment_plan:e.target.value})
                      // }} placeholder='enter instalment plan'/>
                      <>
  <div style={{
                    width:'100%'
                  }}>
                    <h3 style={{textTransform:'capitalize'}}>instalment plane</h3>
                    <div style={{
                      width:'100%',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'space-between'
                    }}>
                      <p style={{
                        textAlign:'center',
                        width:'50%',
                        margin:'0px',
                        fontSize:'19px'
                      }}>
                        from
                      </p>
                      <p
                      style={{
                        textAlign:'center',
                        width:'50%',
                        margin:'0px',
                        fontSize:'19px'
                      }}
                      >
                        to
                      </p>
                    </div>
                  </div>
<div style={{
                        width:'100%'
                      }}>
<div style={{
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between'
                  }}>

                    <Select
                    showSearch
                    onChange={(e)=>{
                      setclosingdata({...closingdata,instal_mark1:e})
                    }}
                    value={closingdata.instal_mark1}
                      style={{
                        width: '50%',
                      }}
                        options={marks.map((item, index) => {
                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                    />

                    <Select
                    showSearch
                    onChange={(e)=>{
                      setclosingdata({...closingdata,instal_mark2:e})
                    }}
                    value={closingdata.instal_mark2}
                      style={{
                        width: '50%',
                      }}
                        options={marks.map((item, index) => {

                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                      />


                  </div>
                      {/* <Select
                      showSearch
                    onChange={(e)=>{
                      setclosingdata({...closingdata,insalment_plan:e})
                    }}
                    value={closingdata.insalment_plan}
                      style={{
                        width: '100%',
                      }}
                        options={marks.map((item, index) => {

                          return { label: `${item.label}`, value: `${item.value}` };
                        })}
                      /> */}


                        {/* <Slider
                      track="inverted"
                      aria-labelledby="track-inverted-slider"
                      getAriaValueText={valuetext3}
                      defaultValue={30}
                      marks={marks2}
                      min={1}
                      max={15}
                    /> */}

                        {/* <Range3
                      _changevalue={ps=>{
                        setclosingdata({...closingdata,
                          insalment_plan:`${ps[0]} - ${ps[1]}`
                          })
                          //console.log("jjfs")
                          //setvalue(ps);
                          //console.log("lkwkeklwelk",ps);
                        }}
                      /> */}
                      </div>

                      </>
                    ):(
                      null
                    )
                  }
                </form>
              </Modal>
              <Modal title="Basic Modal" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
              <>
                <input
                  style={{
                    width:'100%',
                    marginTop:'20px'
                  }}
                  type="date"
                  value={meeting}
                  onChange={(e)=>{
                    setmeeting(e.target.value)
                  }}
                />
                <label style={{marginTop:'10px'}} htmlFor="">Time</label>
                  <input value={clockTime} type="time"
                    style={{
                      width:'100%',
                      padding:'10px',
                      borderRadius:'10px',
                      outline:'none',
                      border:'1px solid #ccc'
                    }}
                    onChange={(e)=>{
                      console.log(e.target.value)
                      setClockTime(e.target.value)
                    }}
                  />
              </>

            </Modal>
            <Modal title="Basic Modal" open={isModalOpencall} onOk={handleOkcall} onCancel={handleCancelcall}>
                <Card
                  style={{
                    width: '100%',
                  }}
                >
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <p>bathroom:{calldata?.bathroom}</p>
                    <p>bedrooms:{calldata?.bathroom}</p>
                  </div>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>compound name:{calldata?.compound_name}</p>
                  <p>delivery date:{calldata?.delivery_date}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>down payment plan:{calldata?.down_payment_plan}</p>
                  <p>monthly payment plan:{calldata?.monthly_payment_plan}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>finishing:{calldata?.installment_year}</p>
                  <p>finishing:{calldata?.finishing}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>max square meter:{calldata?.max_square_meter}</p>
                  <p>min square meter:{calldata?.min_square_meter}</p>
                  </div>

                 <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>property type:{calldata?.property_type}</p>
                  <p>sale type:{calldata?.sale_type}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>sup location name:{calldata?.sub_location}</p>
                  <p>location name:{calldata?.location_data?.location_name}</p>
                  </div>
                </Card>
              </Modal>
    </>
  )
}

export default ClientProfile2
