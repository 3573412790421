import { Modal, Select } from 'antd'
import axios from 'axios'
import { Link } from 'feather-icons-react/build/IconComponents'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const Assigncuscold = () => {
  const localData=localStorage.getItem('authUser');
  let userAuth=localData&&JSON.parse(localData);
  const navigate=useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [employeedata,setemployeedata]=useState({
    name:'',
    phone:'',
    job:'',
    address:'',
    type:'',
  });
  const [coldclients,setcoldclients]=useState([]);
  const getcoldcustomer=()=>{
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/select_assigned_data.php",{
      type:'cold',
      user_type:userAuth?.user_type,
      user_id:userAuth?.agent_id
    }).then((res)=>{
      console.log(res.message)
      setcoldclients(res.message);
    })
  }
  useEffect(()=>{
    getcoldcustomer()
  },[])
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
      if(employeedata.name==""){
      toast.warn("enter client name");
      return;
    }
    if(employeedata.phone==""||isNaN(employeedata.phone)){
      toast.warn("enter client phone(valied)");
      return;
    }
    if(employeedata.job==""){
      toast.warn("enter client job");
      return;
    }
    if(employeedata.address==""){
      toast.warn("enter client address");
      return;
    }
    setIsModalOpen(false);
    const data_Send={
      ...employeedata,
      added_by_agent_id:userAuth.agent_id,
    }
    console.log(data_Send);
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/insert_client.php",data_Send)
    .then((res)=>{
      console.log(res)
      if(res.status==="success"){
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else {
        toast.error("something is error try again");
      }
    }).finally(()=>{
      //setaddloading(false);
    })
  };
  const handleCancel = () => {
    setIsModalOpen(false);

  };
  return (
    <div className='container' style={{
      marginTop:'80px',
      overflow:'auto'
    }}>
      <button className='btn btn-outline-info' type="button"
        onClick={showModal}>
        Add client
      </button>
      <Modal title="Add employee" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered>
        <form>
          <input onChange={(e)=>{
            setemployeedata({...employeedata,name:e.target.value});
          }} type="text" placeholder='enter client name'/>
          <input onChange={(e)=>{
            setemployeedata({...employeedata,phone:e.target.value});
          }} type="text" placeholder='enter client phone'/>
          <input onChange={(e)=>{
            setemployeedata({...employeedata,job:e.target.value});
          }} type="text" placeholder='enter client job'/>
          <input onChange={(e)=>{
            setemployeedata({...employeedata,address:e.target.value});
          }} type="text" placeholder='enter client address'/>
          <Select
defaultValue="lucy"
style={{
  width: "100%",
}}
onChange={(e)=>{
  setemployeedata({...employeedata,type:e});
}}
options={[
  {
    value: 'cold',
    label: 'cold',
  },
  {
    value: 'leads',
    label: 'leads',
  },
]}
/>
        </form>
      </Modal>

      <table className="table table-nowrap table-hover mb-0">
      <thead>
          <tr>
              <th scope="col">number</th>
              <th scope="col">id</th>
              <th scope="col">name</th>
              <th scope="col">phone</th>
              <th scope="col">Assigned Date</th>
              <th scope="col">assigned by</th>
              <th scope="col">address</th>
              <th scope="col">action</th>
{/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
          </tr>
      </thead>
      <tbody>
        {
          coldclients.map((item,index)=>{
            return(
              <tr>
                <th>{index+1}</th>
              <th
              onClick={()=>{
                //navigate("/clientprofile",{state:{data:item}});
                navigate("/clientprofile2",{state:{data:item}});
              }}
              style={{
                cursor:'pointer',
                color:'blue',
                textDecoration:'underline'
              }} scope="row">{item.client_id}</th>
              <td>{item.name}</td>
              <td>{item.phone}</td>
              <td>{moment(item.assigned_date).format('L')}</td>
              <td>{item.assign_agent_name}</td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item.address}</span>
              </td>
              <td>
                <AiFillDelete style={{color:'red',fontSize:'30px',cursor:'pointer'}}/>
                <AiOutlineEdit style={{color:'green',fontSize:'30px',cursor:'pointer'}}/>
              </td>
          </tr>
            )
          })
        }

      </tbody>
      </table>
    </div>
  )
}

export default Assigncuscold
