import React from 'react'
import './assignedcustomer.css'
import axios from 'axios'
const AssignedCustomer = () => {
  const getcustomers=()=>{
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/select_assigned_data.php")
  }
  return (
    <div>
      AssignedCustomer
    </div>
  )
}

export default AssignedCustomer
