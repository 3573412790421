import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css'
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";
import {configureStore} from './store/store';
import AppContextProvider from './components/context/AppContextProvider';
import 'react-range-slider-input/dist/style.css';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter>
      <AppContextProvider>
        <App />
      </AppContextProvider>

      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
reportWebVitals();
