import { Card, Col, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { AppContext } from '../../components/context/AppContextProvider';
import moment from 'moment';



const NotAnswer = () => {
  const authUser=localStorage.getItem("authUser");
  let uData=JSON.parse(authUser);
  // console.log(uData,"uData")
  const {user}=useContext(AppContext);

  // console.log(user)
  let agentData=JSON.parse(localStorage.getItem("authUser"));
  const [pageloading,setpageloading]=useState(true);
  const [data,setData]=useState([]);
  const [originalData,setOriginalData]=useState([]);
  const nav =useNavigate()

  const getNotAnswer=()=>{
    setpageloading(true)
    const data_send={
      type:uData.user_type,
      agent_id:uData.agent_id,
    }
    axios.post('https://camp-coding.tech/U_A_D_Walls/admin/select_not_answer.php',JSON.stringify(data_send))
    .then((res)=>{
      // console.log(res)
      if(Array.isArray(res.message)){
        setData(res.message)
        setOriginalData(res.message)
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setpageloading(false)
    })
  }
  const handleSearch=(txt)=>{
    // if(txt==''){
    //   setData(originalData)
    // }
    // else {
    //   const pushed=[];
    //   for(let i=0;i<originalData.length;i++){
    //     if(originalData[i].client_name.includes(txt)||originalData[i].client_phone.includes(txt))
    //     pushed.push(originalData[i]);
    //   }
    //   setData(pushed)
    // }
    if(txt==''){
      setData(originalData)
    }
    else {
      if(true){
        // console.log('rere')
        let pushed=originalData.filter((it)=>{
          // console.log(it.client_name)
          if(it.client_name!=null){
            if(it.client_name.includes(txt)||it.client_phone.includes(txt)){
              return {...it}
            }
          }
          return null
        });
        console.log(pushed)
        setData(pushed)
        // setData(originalData.filter(it=>it.client_name.includes(txt)||it.client_phone.includes(txt)));
      }
      // else {
      //   setclients(originalColds.filter(it=>it.client_name.includes(txt)||it.client_phone.includes(txt)));
      // }
    }

  }
  useEffect(()=>{
    getNotAnswer()
  },[])

  return (
    <>
      {
        pageloading?(
          [1, 2, 3].map(x => (
            <Col xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div style={{
            marginTop:'30px',
            overflow:'auto'
          }} className='py-5 container'>

      <div className='my-2 p-2'>
        <input placeholder='Search' className='form-control' type="text" onChange={(e)=>{
          e.preventDefault();
          handleSearch(e.target.value)
        }} />
      </div>
      <table class="table">
        <thead className='text-center'>
          <tr>
                <th>number</th>
                <th>id</th>
                <th>client</th>
                <th>Address</th>
                <th>Agent Name</th>
                <th>Response</th>
                <th>Date</th>
                {/* <th>User Offer</th> */}
          </tr>
        </thead>
        <tbody  className='text-center'>
          {data?.map((items ,index)=>{
            return <tr key={index}>
              <td>{index+1}</td>
              <td>
                  {items.call_id}
              </td>
              <td>
                  {items.client_name}
              </td>
              <td>
                  {items.client_address}
              </td>
              <td>
                  {items.agent_name}
              </td>
              <td>
                  {items.response}
              </td>
              <td>
                  {moment(items.date_added).format('L')}
              </td>
              {/* <td>
                  <button className='btn btn-outline-danger'  onClick={()=>{
                    showModal2()
                    setcall_id(items.call_id)
                  }} >Meeting</button>
              </td> */}
            </tr>

          })}

        </tbody>
      </table>

          </div>
        )
      }
    </>
  )
}

export default NotAnswer
