import React, { useEffect, useState } from 'react'
import './clientprofile.css';
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, CardBody, Container, CardHeader } from "reactstrap";
import { Grid } from 'gridjs-react';
import { data, columns } from "../../Tables/advanceTdata";
import axios from 'axios';
import { AiOutlinePlus } from 'react-icons/ai';
import { Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
const ClientProfile = () => {
  const authUser=localStorage.getItem("authUser");
  console.log(authUser)
  const alldata=[
    {
      id:'0',
      date:'12-3-2022',
      sale:'hany',
    },
    {
      id:'0',
      date:'12-3-2022',
      sale:'hany',
    },
    {
      id:'0',
      date:'12-3-2022',
      sale:'hany',
    },
    {
      id:'0',
      date:'12-3-2022',
      sale:'hany',
    },
    {
      id:'0',
      date:'12-3-2022',
      sale:'hany',
    },
  ]
/*   const location=useLocation();
  const {data}=location.state; */
  const [listnumberdata,setlistnumberdata]=useState([]);
  const [answer,setanswer]=useState("");
  const [notanswer,setnotanswer]=useState("");
  const [showansered,setshowansered]=useState("ans");
  const [showdatainter,setshowdatainter]=useState("int");
  const [showresshcudlar,setshowresshcudlar]=useState("");
  const [answer_details,setanswer_details]=useState({});
  const [calltype,setcalltype]=useState("");
  const [calldata,setcalldata]=useState({
    client_id:data.client_id,
    agent_id:'1',
    response:showansered=="ans"?"answer":'not_answer',
    date_to_follow :'',
    answer_details:'interested',
    user_offer:'',
    note:'',
    time:'',
  });
/*   const getlistofnumbers=()=>{
    axios.get("")
    .then((res)=>{
      setlistnumberdata(res.data.message)
      console.log(res.data.message)
    })
  } */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
/*     let senddetails="";
    let client_id="";
    let agent_id=""; */
    /* if(calltype==="answer"&&(calldata.answer_details===""||calldata.answer_details==null)){
      senddetails="interested";
    }
    else if(calltype==="not_answer"&&(calldata.answer_details===""||calldata.answer_details==null)){
      senddetails="phone_closed"
      client_id=data.client_id;
      agent_id="1";
    }
    else {
      senddetails=calldata.answer_details;
    }
    if(showresshcudlar=="schadular"){
      client_id=data.client_id;
      agent_id="1";
    }
    else{
      client_id=data.client_id;
      agent_id="1";
    }*/
    const data_send={
      ...calldata,
/*       answer_details:senddetails,
      client_id,
      agent_id */
    }
    console.log(data_send);
    console.log(data_send);
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/calling_client.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res);
      if(res.status=="success"){
        toast.success(res.message);
        setcalldata({
          client_id:data.client_id,
          agent_id:'1',
          response:'answer',
          date_to_follow :'',
          answer_details:'interested',
          user_offer:'',
          note:'',
          time:'',
        });
      }
      else if(res.status=="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again")
      }
    })
    // axios.post("",JSON.stringify(data_send))
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
/*   useEffect(()=>{
    getlistofnumbers();
  },[]) */

  return (
    <div style={{
      marginTop:'40px',
      marginBottom:'40px'
    }} className='py-5 mb-3'>
    <table className="table mb-0 table-bordered">

    <thead>
      <tr style={{textAlign:'center'}}>
        <th>Id</th>
        <th>Name</th>
        <th>Address</th>
        <th>called</th>
        <th>Job</th>
        <th>Phone</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <tr style={{textAlign:'center'}}>
        <th>{data.client_id}</th>
        <th>{data.name}</th>
        <th>{data.address}</th>
        <th>{data.called}</th>
        <th>{data.job}</th>
        <th>{data.phone}</th>
        <th>
          <button style={{fontSize:'19px'}} type="button" className="btn btn-sm btn-link bg-primary text-white  text-dark text-decoration-none">comments</button>
        </th>
      </tr>
    </tbody>
    </table>
    <div style={{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      padding:'4px 20px'
    }}>
      <h3>List Of Calls</h3>
      <AiOutlinePlus
        onClick={showModal}
        style={{
          color:'green',
          fontSize:'20px',
          cursor:'pointer',
        }}
      />
      <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
{/*         <div style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between'
        }}>
          <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
            <span style={{textTransform:'capitalize'}}>answer</span>
            <input onClick={()=>{
              setshowansered("ans");
            }} type="checkbox"/>
          </div>
          <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
            <span style={{textTransform:'capitalize'}}>not answer</span>
            <input onClick={()=>{
              setshowansered('not')
            }} type="checkbox"/>
          </div>
        </div> */}
        <div style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between'
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
              <input
              checked={showansered=="ans"}
                onClick={() => {
                  setshowansered("ans");
                  setcalltype("answer")
                  setcalldata({...calldata,answer_details:"interested"});
                  setcalldata({...calldata,response:"answer"});
                }}
                style={{ display: "block" }}
                type="radio"
                id="guide"
                name="fav_language"
                value="guide"
              />
              <label
                onClick={() => {
                  setshowansered("ans");

                  setcalltype("answer")
                  setcalldata({...calldata,response:"answer"});
                  setcalldata({...calldata,answer_details:"interested"});
                }}
                htmlFor="guide"
                style={{
                  fontSize: "20px",
                  textTransform:'capitalize'
                }}
              >
                answer
              </label>
            </div>
            <div style={{
              display:'flex',
              alignItems:'center',
              gap:'3px'
            }}>
              <input
              checked={showansered=="not"}
                onClick={(e) => {
                  setshowansered("not")
                  setcalltype("not_answer")
                  setcalldata({...calldata,answer_details:"phone_closed"});

                  setcalldata({...calldata,response:"not_answer"});
                }}
                style={{ display: "block" }}
                type="radio"
                id="company"
                name="fav_language"
                value="company"
              />
              <label
              onClick={(e) => {
                  setshowansered("not")
                  setcalldata({...calldata,answer_details:"phone_closed"});

                  setcalltype("not_answer")
                  setcalldata({...calldata,response:"not_answer"});
                }} style={{ fontSize: "20px" ,textTransform:'capitalize'}} htmlFor="company">
                not answerd
              </label>
            </div>
        </div>
          {
            showansered=="not"?
            <>
              <select
                onChange={(e)=>{
                  console.log(e.target.value)
                  setnotanswer(e);
                  setcalldata({...calldata,answer_details:e.target.value})
                }}
                placeholder="enter respone"
                  style={{
                    width: "100%",
                    height:'40px',
                    backgroundColor:'#ccc',
                    border:'1px solid #ccc'

                  }}
              >
                <option value="phone_closed">phone closed</option>
                <option value="out_of_service">out of service</option>
                <option value="wrong_number">wrong number</option>
                <option value="ring_without_response">ring without response</option>
              </select>
            </>
            :
            showansered=="ans"?
            <>
              <select
                onChange={(e)=>{
                  //console.log(e)
                  setcalldata({...calldata,answer_details:e.target.value})
                  console.log(e.target.value)
                  setanswer(e);
                }}
                placeholder="enter answer"
                  style={{
                    width: "100%",
                    height:'40px',
                    backgroundColor:'#ccc',
                    border:'1px solid #ccc'

                  }}
              >
                <option

                onClick={(e)=>{
                  console.log(e.target.value)
                  setshowdatainter("int");
                  setcalldata({...calldata,answer_details:e.target.value})
                }}
                value="interested">interested</option>
                <option onClick={(e)=>{
                  setshowdatainter("not_int");
                }} value="not_interested">not interested</option>
                <option onClick={(e)=>{
                  setshowdatainter("int_not_now");
                }} value="interested_but_not_now">interested but not now</option>
              </select>
              {
                showdatainter=="int_not_now"?
                <>
                  <input onChange={(e)=>{setcalldata({...calldata,date_to_follow:e.target.value})}} style={{width:'100%',marginTop:'10px'}} type='date'/>
                </>:
                showdatainter=="int"?
                <>
                          <div style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between'
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
              <input
              checked={showresshcudlar==""}
                  onClick={(e) => {
                    setshowresshcudlar("");
                    setanswer("answer");
                    }}
                style={{ display: "block" }}
                type="radio"
                id="continue"
                //name="fav_language"
                //value="guide"
              />
              <label
                onClick={() => {
                setshowresshcudlar("");
                }}
                htmlFor="continue"
                style={{
                  fontSize: "20px",
                  textTransform:'capitalize'
                }}
              >
                continue
              </label>
            </div>
            <div style={{
              display:'flex',
              alignItems:'center',
              gap:'3px'
            }}>
              <input
              checked={showresshcudlar=="schadular"}
                onClick={(e) => {
                  setshowresshcudlar("schadular");
                  setanswer("answer");
                  }}
                style={{ display: "block" }}
                type="radio"
                id=""
                name=""
                value=""
              />
              <label
              onClick={(e) => {
                setshowresshcudlar("schadular");
                }} style={{ fontSize: "20px" ,textTransform:'capitalize'}} htmlFor="rescadular">
                reschedular
              </label>
            </div>
        </div>
                {
                  showresshcudlar=="schadular"?(
                    <div>
                      <input style={{
                        width:'100%',
                        marginBottom:'20px'
                      }} type="time" name="" id=""
                      onChange={(e)=>{
                        setcalldata({...calldata,time:e.target.value})
                      }}
                      />
                      <input
                      onChange={(e)=>{
                        setcalldata({...calldata,date_to_follow:e.target.value});
                      }}
                      style={{
                        width:'100%'
                      }} type="date" />
                    </div>
                  ):(
                    <div>
                      <div style={{
                        display:'flex',
                        alignItems:'center',
                        gap:'4px',
                        marginBottom:'20px'
                      }}>
                        <input type="checkbox"/>
                        <span>offer sent</span>
                      </div>
                      <div style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-between',
                        border:'1px solid #ccc',
                        padding:'10px',
                        borderRadius:'10px',
                        marginBottom:'20px',
                        flexWrap:'wrap'
                      }}>
                        <h4 style={{
                          width:'100%'
                        }}>follow up call</h4>
                      <input style={{
                        width:'48%',
                        height:'40px',
                        border:'1px solid #ccc',
                        padding:'10px',
                        borderRadius:'10px'
                      }} type="time"
                      onChange={(e)=>{
                        setcalldata({...calldata,time:e.target.value})
                      }}
                      name="" id="" />
                      <input style={{
                        width:'48%',
                        height:'40px',
                        border:'1px solid #ccc',
                        padding:'10px',
                        borderRadius:'10px'
                      }}
                      onChange={(e)=>{
                        setcalldata({...calldata,date_to_follow:e.target.value})
                      }}
                      type="date" />
                    </div>
                    <input
                      onChange={(e)=>{
                        setcalldata({...calldata,user_offer:e.target.value})
                      }}
                      style={{
                        width:'100%',
                        marginBottom:'20px',
                        height:'40px',
                        border:'1px solid #ccc',
                        padding:'10px',
                        borderRadius:'10px'
                      }}  type="text" placeholder='enter link of unit'/>
                    <input
                      onChange={(e)=>{
                        setcalldata({...calldata,note:e.target.value})
                      }}
                      style={{
                        width:'100%',
                        marginBottom:'20px',
                        height:'80px',
                        border:'1px solid #ccc',
                        padding:'10px',
                        borderRadius:'10px'
                      }}  type="text" placeholder='enter other details'/>
                    </div>
                  )
                }
                </>
                :
                <>

                </>
              }
            </>
            :null
          }
      </Modal>
    </div>
    <table style={{marginTop:'30px'}} className="table mb-0 table-bordered">

    <thead>
      <tr style={{textAlign:'center'}}>
        <th>Id</th>
        <th>date</th>
        <th>sale name</th>
        <th style={{textAlign:'center'}}>details</th>
      </tr>
    </thead>
    <tbody>
      {
        alldata.map((item,index)=>{
          return (
            <tr style={{textAlign:'center'}}>
              <th>{item.id}</th>
              <th>{item.date}</th>
              <th>{item.sale}</th>
              <th style={{textAlign:'center'}}><button className='btn btn-primary'>details</button></th>
            </tr>
          )
        })
      }
    </tbody>
    </table>

    </div>
  )
}

export default ClientProfile
