import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { AppContext } from '../../components/context/AppContextProvider'//context/AppContextProvider
import axios from 'axios';
import { Card, Col, Modal, Select } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
const AllFollowsUp = () => {
  const {user}=useContext(AppContext);
  const navigate=useNavigate()
  const location=useLocation();
  const agent_id=JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [pageLoading,setPageLoading]=useState(false);
  const [meetings,setMettings]=useState([]);
  const [calls,setCalls]=useState([]);
  // const [calldata,setcallreq]=useState({});
  const [callData,setCallData]=useState({})
  const [filteredDate,setFilteredDate]=useState('');
  const [filterAnswerDet,setFilterAnswerDet]=useState('all');
  const [originalData,setOriginalData]=useState([]);
  const [sales,setSales]=useState([{agent_id:'all',name:'all'}]);
  const [showReqModal,setShowReqModal]=useState(false)
  const [calldata,setCalldata]=useState({});
  const [selectedSale,setSelectedSale]=useState('all');
  const getFollowsData=()=>{
    setPageLoading(true)

    axios.get('https://camp-coding.tech/U_A_D_Walls/admin/all_follows.php')
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        setMettings(res?.message?.meetings);
        setCalls(res?.message?.callings);
        setOriginalData(res?.message?.callings);
      }
    })
    .catch((e)=>{
      console.log(e);
    })
    .finally(()=>{
      setPageLoading(false)
    })
  }

  const getSales=()=>{
    axios.get('https://camp-coding.tech/U_A_D_Walls/admin/select_selles.php')
    .then((res)=>{
      console.log(res)
      if(Array.isArray(res.message)){
        setSales([{name:'all',agent_id:'all'},...res.message])
        console.log(res.message)
      }
    })
    .catch(e=>console.log(e))
  }

  useEffect(()=>{
    if(filterAnswerDet=='all'){
      if(filteredDate==''){
        if(selectedSale=='all'){
          setCalls(originalData);
        }
        else {
          setCalls(originalData.filter(it=>it.agent_id==selectedSale));
        }
      }
      else {
        if(selectedSale=='all'){
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate))
        }
        else {
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.agent_id==selectedSale))
        }
      }
    }
    else {
      if(filteredDate==''){
        if(selectedSale=='all'){
          setCalls(originalData.filter(item=>item.answer_details==filterAnswerDet))
        }
        else {
          setCalls(originalData.filter(item=>item.answer_details==filterAnswerDet&&item.agent_id==selectedSale))
        }
      }
      else {
        if(selectedSale=='all'){
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.answer_details==filterAnswerDet))
        }
        else {
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.answer_details==filterAnswerDet&&item.agent_id==selectedSale))
        }
      }
    }
  },[filterAnswerDet])

  useEffect(()=>{
    if(filteredDate==''){
      if(filterAnswerDet=='all'){
        if(selectedSale=='all'){
          setCalls(originalData);
        }
        else {
          setCalls(originalData.filter(it=>it.agent_id==selectedSale));
        }
      }
      else {
        if(selectedSale=='all'){
          originalData.filter(item=>item.answer_details==filterAnswerDet)
        }
        else {
          originalData.filter(item=>item.answer_details==filterAnswerDet&&item.agent_id==selectedSale)
        }
      }
    }
    else {
      console.log('here1')
      if(filterAnswerDet=='all'){
        if(selectedSale=='all'){
      console.log('here2')
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate))
        }
        else {
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.agent_id==selectedSale))
        }
      }
      else {
        if(selectedSale=='all'){
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.answer_details==filterAnswerDet))
        }
        else {
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.answer_details==filterAnswerDet&&item.agent_id==selectedSale))
        }
      }
    }
  },[filteredDate])
  useEffect(()=>{
    if(selectedSale=='all'){
      if(filterAnswerDet=='all'){
        if(filteredDate==''){
          setCalls(originalData);
        }
        else {
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate));
        }
      }
      else {
        if(filteredDate==''){
          setCalls(originalData.filter(it=>it.answer_details==filterAnswerDet));
        }
        else {
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.answer_details==filterAnswerDet))
        }
      }
      // else if()
    }
    else {
      if(filterAnswerDet=='all'){
        if(filteredDate==''){
          setCalls(originalData.filter(it=>it.agent_id==selectedSale));
        }
        else {
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.agent_id==selectedSale));
        }
      }
      else {
        if(filteredDate==''){
          setCalls(originalData.filter(it=>it.answer_details==filterAnswerDet&&it.agent_id==selectedSale));
        }
        else {
          setCalls(originalData.filter(item=>moment(item.date_added).format("L")==filteredDate&&item.answer_details==filterAnswerDet&&item.agent_id==selectedSale))
        }
      }
    }
  },[selectedSale])
  useEffect(()=>{
    getFollowsData()
    getSales()
  },[])
  return (
    <>
      {
        pageLoading?
        (
          [1, 2, 3].map(x => (
            <Col  xs={24} md={24} lg={24} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        )
        :
        (
          <div style={{overflowX:'auto',marginTop:'78px'}} className='py-6 p-6 container'  >


            <div className='my-2'>
              <label htmlFor="">Sales</label>
            <Select

              style={{ width:'100%' }}
              placeholder="enter answer"
              //onChange={handleChange}
              onChange={(e)=>{
                console.log(e)
                // setanswertype(e);
                setSelectedSale(e)
              }}
              value={selectedSale}
              options={sales.map((item)=>{
                return {label:item.name,value:item.agent_id}
              })}
            />
              {/* <select name="" id=""></select> */}
            </div>

            <div style={{margin:'30px 0px',overflowX:'auto'}}>
              <div>
                <label htmlFor="">Answer Details</label>
                <Select
                  style={{ width:'100%' }}
                  placeholder="enter answer"
                  //onChange={handleChange}
                  onChange={(e)=>{
                    console.log(e)
                    // setanswertype(e);
                    setFilterAnswerDet(e)
                  }}
                  value={filterAnswerDet}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'interested', label: 'interested' },
                    { value: 'out_of_service', label: 'out_of_service' },
                    { value: 'not_interested', label: 'not_interested' },
                    { value: 'interested_but_not_now', label: 'interested_but_not_now' },
                  ]}
                />
              </div>
              <div>
                <label htmlFor="">Date Filter</label>
                <input className='form-control' type="date" onChange={(e)=>{
                  setFilteredDate(moment(e.target.value).format("L"))
                }} />
              </div>
            </div>
            <div>Requests</div>
            <table style={{overflowX:'auto'}}  className="table table-nowrap table-hover mb-0">
      <thead>
          <tr>
              <th scope="col">id</th>
              <th scope="col">Client Name</th>
              <th scope="col">phone</th>
              <th scope="col">address</th>
              <th scope="col">Answer Details</th>
              <th scope="col">Date To Follow</th>
              <th scope="col">Call Date</th>
              <th scope="col">User Offer</th>
              <th scope="col">Time</th>
              <th scope="col">request</th>
{/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
          </tr>
      </thead>
      <tbody>
        {
          calls&&Array.isArray(calls)&&calls.map((item,index)=>{
            return(
              <tr>
              <th
              onClick={()=>{
                //navigate("/clientprofile",{state:{data:item}});
                navigate("/clientprofile2",{state:{data:item}});
              }}
              style={{
                cursor:'pointer',
                color:'blue',
                textDecoration:'underline'
              }} scope="row">{item.client_id}</th>
              <td><Link to="#" className="text-dark">{item?.name}</Link></td>
              <td><Link to="#" className="text-dark">{item?.phone}</Link></td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item?.address}</span>
              </td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item?.answer_details}</span>

              </td>
              <td>
              <span className="badge badge-soft-primary font-size-12">{item?.date_to_follow}</span>

              </td>
              <td><span className="badge badge-soft-primary font-size-12">{moment(item?.date_added).format("L")}</span></td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item?.user_offer}</span>
              </td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item?.time}</span>
              </td>
              <td><button onClick={()=>{
                    setCalldata(item.request)
                    setShowReqModal(true)
              }} className='btn btn-primary'>request</button></td>
          </tr>
            )
          })
        }

      </tbody>
                </table>
                <hr />
                <div className='mt-5 mb-2'>Meetings</div>


                <table className="table table-nowrap table-hover mb-0">
      <thead>
          <tr>
              <th scope="col">id</th>
              <th scope="col">Client Name</th>
              <th scope="col">phone</th>
              <th scope="col">Date</th>
              <th scope="col">time</th>
{/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
          </tr>
      </thead>
      <tbody>
        {
          meetings&&Array.isArray(meetings)&&meetings.map((item,index)=>{
            return(
              <tr>
              <th
              onClick={()=>{
                //navigate("/clientprofile",{state:{data:item}});
                navigate("/clientprofile2",{state:{data:item}});
              }}
              style={{
                cursor:'pointer',
                color:'blue',
                textDecoration:'underline'
              }} scope="row">{item.client_id}</th>
              <td><Link to="#" className="text-dark">{item?.name}</Link></td>
              <td><Link to="#" className="text-dark">{item?.phone}</Link></td>
              <td><p>{item?.date}</p></td>
              <td><p>{item?.time}</p></td>

          </tr>
            )
          })
        }

      </tbody>
                </table>

          </div>
        )
      }
            <Modal title="Request Data" open={showReqModal} onOk={()=>{
        setShowReqModal(false)
      }} onCancel={()=>{
        setShowReqModal(false)
      }}>
                <Card
                  style={{
                    width: '100%',
                  }}
                >
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <p>bathroom:{calldata?.bathroom}</p>
                    <p>bedrooms:{calldata?.bathroom}</p>
                  </div>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>compound name:{calldata?.compound_name}</p>
                  <p>delivery date:{calldata?.delivery_date}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>down payment plan:{calldata?.down_payment_plan}</p>
                  <p>monthly payment plan:{calldata?.monthly_payment_plan}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>finishing:{calldata?.installment_year}</p>
                  <p>finishing:{calldata?.finishing}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>max square meter:{calldata?.max_square_meter}</p>
                  <p>min square meter:{calldata?.min_square_meter}</p>
                  </div>

                 <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>property type:{calldata?.property_type}</p>
                  <p>sale type:{calldata?.sale_type}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>sup location name:{calldata?.sub_location}</p>
                  <p>location name:{calldata?.location_data?.location_name}</p>

                  </div>
                </Card>
      </Modal>
    </>
  )
}

export default AllFollowsUp
