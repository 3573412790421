import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import * as XLSX from 'xlsx'
//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link } from 'react-router-dom';

import { withTranslation } from "react-i18next";
import withRouter from "../../components/Common/withRouter";

import SidebarData from './SidebarData';
import { AppContext } from '../../components/context/AppContextProvider';
import { Modal } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';

const SidebarContent = (props) => {
  const [myfile,setmyfile]=useState(null);
  const {user}=useContext(AppContext)
  console.log(user)
  const ref = useRef();


  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    const formdata=new FormData();
    formdata.append("file_attachment",myfile);
    formdata.append("added_by_agent_id",user.agent_id)
    console.log(formdata)
    //console.log(data_send);
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/upload_excel_file.php",formdata)
    .then((res)=>{
      console.log(res)
      toast.success(`done for ${res.add_counter}`)
      toast.warn(`duplication for ${res.duplication_counter}`)
    })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [xlsData, setxlsData] = useState([])
const handleFileUpload=(e)=>{
  const reader=new FileReader();
  reader.readAsBinaryString(e.target.files[0]);
  reader.onload=(e)=>{
    const data=e.target.result;
    const workbook=XLSX.read(data,{type:'binary'});
    const sheetname=workbook.SheetNames[0]
    const sheet=workbook.Sheets[sheetname]
    const parseData=XLSX.utils.sheet_to_json(sheet)
    setxlsData(parseData)
  }
}

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="sidebar-menu-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            {user.user_type=="sells"?(
                (SidebarData || []).filter((it)=>it.saleshow).map((item, key) => (
                  <React.Fragment key={key}>
                    {item.isMainMenu ? (
                      <li className="menu-title">{props.t(item.label)}</li>
                    ) : (
                      <li key={key}>
                        <Link to={item.url ? item.url : "/#"}
                          className={item.url ? "" : "has-arrow"}
                        >
                          <i
                            className={item.icon}
                          ></i>
                          <span
                            className="menu-item"
                          >
                            {props.t(item.label)}
                          </span>
                          {item.issubMenubadge && (
                            <span
                              className={
                                "badge rounded-pill " + item.bgcolor
                              }
                            >
                              {" "}
                              {props.t(item.badgeValue)}
                              {" "}
                            </span>
                          )}

                        </Link>
                        {
                          user.user_type=="sells"?(
                            item.subItem && (
                              <ul className="sub-menu">
                                {item.subItem.filter((it)=>it.saleshow).map((item, key) => (
                                  <li key={key}>
                                    <Link
                                      to={item.link}
                                      className={
                                        item.subMenu && "has-arrow"
                                      }
                                    >
                                      {props.t(item.sublabel)}
                                    </Link>
                                    {item.subMenu && (
                                      <ul className="sub-menu">
                                        {item.subMenu.map((item, key) => (
                                          <li key={key}>
                                            <Link to="#">{props.t(item.title)}</Link>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            )
                          ):(
                            item.subItem && (
                              <>
                                <ul className="sub-menu">
                                  {item.subItem.filter((it)=>it.adminshow).map((item, key) => (
                                  <li key={key}>
                                    <Link
                                      to={item.link}
                                      className={
                                        item.subMenu && "has-arrow"
                                      }
                                    >
                                      {props.t(item.sublabel)}
                                    </Link>
                                    {item.subMenu && (
                                      <ul className="sub-menu">
                                        {item.subMenu.map((item, key) => (
                                          <li key={key}>
                                            <Link to="#">{props.t(item.title)}</Link>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </li>
                                  ))}
                                </ul>

                              </>
                            )

                          )
                        }

                      </li>
                    )}
                  </React.Fragment>
                ))
            ):
            user.user_type=='admin'?
            (
              <>
                {
                    (SidebarData || []).filter((it)=>it.adminshow).map((item, key) => (
                      <React.Fragment key={key}>
                        {item.isMainMenu ? (
                          <li className="menu-title">{props.t(item.label)}</li>
                        ) : (
                          <li key={key}>
                            <Link to={item.url ? item.url : "/#"}
                              className={item.url ? "" : "has-arrow"}
                            >
                              <i
                                className={item.icon}
                              ></i>
                              <span
                                className="menu-item"
                              >
                                {props.t(item.label)}
                              </span>
                              {item.issubMenubadge && (
                                <span
                                  className={
                                    "badge rounded-pill " + item.bgcolor
                                  }
                                >
                                  {" "}
                                  {props.t(item.badgeValue)}
                                  {" "}
                                </span>
                              )}

                            </Link>
                            {
                              user.user_type=="sells"?(
                                item.subItem && (
                                  <ul className="sub-menu">
                                    {item.subItem.filter((it)=>it.saleshow).map((item, key) => (
                                      <li key={key}>
                                        <Link
                                          to={item.link}
                                          className={
                                            item.subMenu && "has-arrow"
                                          }
                                        >
                                          {props.t(item.sublabel)}
                                        </Link>
                                        {item.subMenu && (
                                          <ul className="sub-menu">
                                            {item.subMenu.map((item, key) => (
                                              <li key={key}>
                                                <Link
                                                to={item.link}>{props.t(item.title)}</Link>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )
                              ):(
                                item.subItem && (
                                  <ul className="sub-menu">

                                    {item.subItem.filter((it)=>it.adminshow).map((item, key) => (
                                      <li key={key}>
                                        <Link
                                          to={item.link}
                                          className={
                                            item.subMenu && "has-arrow"
                                          }
                                        >
                                          {props.t(item.sublabel)}
                                        </Link>
                                        {item.subMenu && (
                                          <ul className="sub-menu">
                                            {item.subMenu.map((item, key) => (
                                              <li key={key}>
                                                <Link to={item.link}>{props.t(item.title)}</Link>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )
                              )
                            }

                          </li>
                        )}
                      </React.Fragment>
                    ))
                }
                <div style={{
                  textAlign:'center'
                }}>
                <li
                  onClick={showModal}
                  //className='btn btn-primary w-75'
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    color:'white',
                    margin:'10px auto',
                    cursor:'pointer'
                  }}
                >
                  <span style={{fontSize:'22px'}}>+</span>
                  <span>customer</span>
                </li>
                <Modal title="add customer" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <input type='file' accept='.xlsx ,.xls'
                  /*  onChange={handleFileUpload} */
                  onChange={(e)=>{
                    handleFileUpload(e);
                    setmyfile(e.target.files[0]);
                  }}
                />
                </Modal>
                </div>
              </>
            )
            :
            user.user_type=='operation'?
            (
              <>
                {
                    (SidebarData || []).filter((it)=>it.operationshow).map((item, key) => (
                      <React.Fragment key={key}>
                        {item.isMainMenu ? (
                          <li className="menu-title">{props.t(item.label)}</li>
                        ) : (
                          <li key={key}>
                            <Link to={item.url ? item.url : "/#"}
                              className={item.url ? "" : "has-arrow"}
                            >
                              <i
                                className={item.icon}
                              ></i>
                              <span
                                className="menu-item"
                              >
                                {props.t(item.label)}
                              </span>
                              {item.issubMenubadge && (
                                <span
                                  className={
                                    "badge rounded-pill " + item.bgcolor
                                  }
                                >
                                  {" "}
                                  {props.t(item.badgeValue)}
                                  {" "}
                                </span>
                              )}


                            </Link>
                            {
                              user.user_type=="sells"?(
                                <>
                                  {
                                    item.subItem && (
                                      <ul className="sub-menu">
                                        {item.subItem.filter((it)=>it.saleshow).map((item, key) => (
                                          <li key={key}>
                                            <Link
                                              to={item.link}
                                              className={
                                                item.subMenu && "has-arrow"
                                              }
                                            >
                                              {props.t(item.sublabel)}
                                            </Link>
                                            {item.subMenu && (
                                              <ul className="sub-menu">
                                                {item.subMenu.map((item, key) => (
                                                  <li key={key}>
                                                    <Link
                                                    to={item.link}>{props.t(item.title)}</Link>
                                                  </li>
                                                ))}
                                              </ul>
                                            )}
                                          </li>
                                        ))}
                                      </ul>
                                    )
                                  }
                                  <h5>ereiro</h5>
                                </>
                              ):(
                                item.subItem && (
                                  <ul className="sub-menu">

                                    {item.subItem.filter((it)=>it.adminshow).map((item, key) => (
                                      <li key={key}>
                                        <Link
                                          to={item.link}
                                          className={
                                            item.subMenu && "has-arrow"
                                          }
                                        >
                                          {props.t(item.sublabel)}
                                        </Link>
                                        {item.subMenu && (
                                          <ul className="sub-menu">
                                            {item.subMenu.map((item, key) => (
                                              <li key={key}>
                                                <Link to={item.link}>{props.t(item.title)}</Link>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )
                              )
                            }

                          </li>
                        )}
                      </React.Fragment>
                    ))
                }
                <div style={{
                  textAlign:'center'
                }}>
                {/* <li
                  onClick={showModal}
                  //className='btn btn-primary w-75'
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    color:'white',
                    margin:'10px auto',
                    cursor:'pointer'
                  }}
                >
                  <span style={{fontSize:'22px'}}>+</span>
                  <span>customer</span>
                </li> */}
                <Modal title="add customer" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <input type='file' accept='.xlsx ,.xls'
                  /*  onChange={handleFileUpload} */
                  onChange={(e)=>{
                    handleFileUpload(e);
                    setmyfile(e.target.files[0]);
                  }}
                />
                </Modal>
                </div>
                <div style={{
                  textAlign:'center'
                }}>
                <li
                  onClick={showModal}
                  //className='btn btn-primary w-75'
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    color:'white',
                    margin:'10px auto',
                    cursor:'pointer'
                  }}
                >
                  <span style={{fontSize:'22px'}}>+</span>
                  <span>customer</span>
                </li>
                <Modal title="add customer" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <input type='file' accept='.xlsx ,.xls'
                  /*  onChange={handleFileUpload} */
                  onChange={(e)=>{
                    handleFileUpload(e);
                    setmyfile(e.target.files[0]);
                  }}
                />
                </Modal>
                </div>
              </>
            )
            :

            (
              <>
                {
                    (SidebarData || []).filter((it)=>it.showteal).map((item, key) => (
                      <React.Fragment key={key}>
                        {item.isMainMenu ? (
                          <li className="menu-title">{props.t(item.label)}</li>
                        ) : (
                          <li key={key}>
                            <Link to={item.url ? item.url : "/#"}
                              className={item.url ? "" : "has-arrow"}
                            >
                              <i
                                className={item.icon}
                              ></i>
                              <span
                                className="menu-item"
                              >
                                {props.t(item.label)}
                              </span>
                              {item.issubMenubadge && (
                                <span
                                  className={
                                    "badge rounded-pill " + item.bgcolor
                                  }
                                >
                                  {" "}
                                  {props.t(item.badgeValue)}
                                  {" "}
                                </span>
                              )}

                            </Link>
                            {
                              user.user_type=="sells"?(
                                item.subItem && (
                                  <ul className="sub-menu">
                                    {item.subItem.filter((it)=>it.saleshow).map((item, key) => (
                                      <li key={key}>
                                        <Link
                                          to={item.link}
                                          className={
                                            item.subMenu && "has-arrow"
                                          }
                                        >
                                          {props.t(item.sublabel)}
                                        </Link>
                                        {item.subMenu && (
                                          <ul className="sub-menu">
                                            {item.subMenu.map((item, key) => (
                                              <li key={key}>
                                                <Link
                                                to={item.link}>{props.t(item.title)}</Link>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )
                              ):(
                                item.subItem && (
                                  <ul className="sub-menu">

                                    {item.subItem.filter((it)=>it.adminshow).map((item, key) => (
                                      <li key={key}>
                                        <Link
                                          to={item.link}
                                          className={
                                            item.subMenu && "has-arrow"
                                          }
                                        >
                                          {props.t(item.sublabel)}
                                        </Link>
                                        {item.subMenu && (
                                          <ul className="sub-menu">
                                            {item.subMenu.map((item, key) => (
                                              <li key={key}>
                                                <Link to={item.link}>{props.t(item.title)}</Link>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </li>
                                    ))}
                                  </ul>
                                )
                              )
                            }

                          </li>
                        )}
                      </React.Fragment>
                    ))
                }
                <div style={{
                  textAlign:'center'
                }}>
                {/* <li
                  onClick={showModal}
                  //className='btn btn-primary w-75'
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    color:'white',
                    margin:'10px auto',
                    cursor:'pointer'
                  }}
                >
                  <span style={{fontSize:'22px'}}>+</span>
                  <span>customer</span>
                </li> */}
                <Modal title="add customer" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <input type='file' accept='.xlsx ,.xls'
                  /*  onChange={handleFileUpload} */
                  onChange={(e)=>{
                    handleFileUpload(e);
                    setmyfile(e.target.files[0]);
                  }}
                />
                </Modal>
                </div>
              </>
            )

            }
            {console.log(user.user_type)}
            {
              user.user_type=='sells'&&
              <div style={{
                textAlign:'center'
              }}>
              <li
                onClick={showModal}
                //className='btn btn-primary w-75'
                style={{
                  display:'flex',
                  flexDirection:'column',
                  color:'white',
                  margin:'10px auto',
                  cursor:'pointer'
                }}
              >
                <span style={{fontSize:'22px'}}>+</span>
                <span>customer</span>
              </li>
              <Modal title="add customer" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
              <input type='file' accept='.xlsx ,.xls'
                /*  onChange={handleFileUpload} */
                onChange={(e)=>{
                  handleFileUpload(e);
                  setmyfile(e.target.files[0]);
                }}
              />
              </Modal>
              </div>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
