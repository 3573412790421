import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TableContainer from "./../../../components/Common/TableContainer";
import { InvoiceData } from "./../../../CommonData/Data/Invoce";

const InvoiceListTable = () => {
    const columns =
        [
            {
                Header: 'Order ID',
                Cell: (cell) => {
                    return (
                        <span>{cell.cell.row.original.id}</span>
                    )
                }
            },
            {
                Header: 'Date',
                accessor: 'date',
                Filter: false,
            },
            {
                Header: 'Billing Name',
                accessor: 'BillingName',
                Filter: false,
            },
            {
                Header: 'Amount',
                accessor: 'Amount',
                Filter: false,
            },
            {
                Header: 'Status',
                Cell: (cell) => {
                    switch (cell.cell.row.original.Status) {
                        case 'Paid':
                            return <span className="badge badge-pill badge-soft-success font-size-12">{cell.cell.row.original.Status}</span>;

                        case 'Pending':
                            return <span className="badge badge-pill badge-soft-warning font-size-12">{cell.cell.row.original.Status}</span>;

                        default:
                            return <span className="badge badge-pill badge-soft-success font-size-12">{cell.cell.row.original.Status}</span>
                    }
                }
            },
            {
                Header: 'Action',
                Cell: (cell) => {
                    return (
                        <>
                            <UncontrolledDropdown>
                                <DropdownToggle className="btn btn-light btn-sm" tag="button" data-bs-toggle="dropdown" direction="start">
                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem>Edit</DropdownItem>
                                    <DropdownItem>Print</DropdownItem>
                                    <DropdownItem>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </>
                    )
                }
            },
        ]
    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={InvoiceData}
                isGlobalFilter={true}
                customPageSize={10}
                className="Invoice table"
            />
        </React.Fragment>
    )
}

export default InvoiceListTable;