
// Import Images

import avatar1 from '../../assets/images/users/avatar-1.jpg';
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import avatar3 from '../../assets/images/users/avatar-3.jpg';
import avatar5 from '../../assets/images/users/avatar-5.jpg';
import avatar6 from '../../assets/images/users/avatar-6.jpg';
import avatar7 from '../../assets/images/users/avatar-7.jpg';
import avatar8 from '../../assets/images/users/avatar-8.jpg';
import avatar9 from '../../assets/images/users/avatar-9.jpg';

const UserGridData = [
    {
        id: 1,
        src: avatar2,
        name: "Phyllis Gatlin",
        designation: "Full Stack Developer",
    },
    {
        id: 2,
        src: avatar1,
        name: "James Nix",
        designation: "Full Stack Developer",
    },
    {
        id: 3,
        src: avatar3, name: "Darlene Smith",
        designation: "UI/UX Designer"
    },
    {
        id: 4,
        name: "William Swift",
        designation: "Backend Developer"
    },
    {
        id: 5,
        name: "Kevin West",
        designation: "Full Stack Developer"
    },
    {
        id: 6,
        src: avatar6,
        name: "Tommy Hayes",
        designation: "Backend Developer",
    },
    {
        id: 7,
        src: avatar8,
        name: "Diana Owens",
        designation: "UI/UX Designer"
    },
    {
        id: 8,
        src: avatar9,
        name: "Paul Sanchez",
        designation: "Full Stack Developer",
    },
    {
        id: 9,
        src: avatar5,
        name: "Paul Sanchez",
        designation: "Full Stack Developer",
    },
    {
        id: 10,
        src: avatar7,
        name: "Paul Sanchez",
        designation: "Full Stack Developer",
    },
    {
        id: 11,
        name: "Paul Sanchez",
        designation: "Full Stack Developer"
    },
    {
        id: 12,
        src: avatar3,
        name: "Paul Sanchez",
        designation: "Full Stack Developer",
    },
];

export default UserGridData;
