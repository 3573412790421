import { Card, Col } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const DoneDeal = () => {
  const userData=JSON.parse(localStorage.getItem("authUser"));
  const [pageLoading,setPageLoading]=useState(false);
  const [deals,setDeals]=useState([])
  const getDeals=()=>{
    setPageLoading(true)
    const data_send={
      agent_id:userData.agent_id,
      type:userData.user_type
    }
    console.log(data_send)
    axios.post("https://camp-coding.tech/U_A_D_Walls/admin/closing_deal.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(Array.isArray(res.message)){
        setDeals(res.message)
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setPageLoading(false)
    })
  }
  useEffect(()=>{
    getDeals()
  },[])
  return (
    <div className='down p-2 container' style={{marginTop:'70px',overflowX:'auto'}}>
      {
        pageLoading?
        (
          [1, 2, 3].map(x => (
            <Col  xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        )
        :
        (
          <table style={{overflowX:'auto'}} className="table table-nowrap table-hover mb-0">
           <thead>
               <tr>
                  <th>number</th>
                  <th scope="col">id</th>
                  <th scope="col">unit_code</th>
                  <th scope="col">total price</th>
                  <th scope="col">down payment</th>
                  <th scope="col">payment type</th>
                  <th scope="col">installment plan</th>
                  <th scope="col">contract image</th>
                  <th scope="col">date</th>
               </tr>
           </thead>
           <tbody>
             {
               deals.map((item,index)=>{
                 return(
                   <tr>
                    <th>{index+1}</th>
                   <th scope="row">{item.deal_id}</th>
                   <td><Link to="#" className="text-dark">{item.unit_code}</Link></td>
                   <td><Link to="#" className="text-dark">{item.total_price}</Link></td>
                   <td>
                     {item.down_payment}
                   </td>
                   <td>
                       <span className="badge badge-soft-primary font-size-12">{item.payment_type}</span>
                   </td>
                   <td>
                     {item.installment_plan}
                   </td>
                   <td>
                     <img style={{width:'100px'}} src={item.contract_image_url} alt="" />
                   </td>
                   <td>
                    <p>{item.date}</p>
                   </td>
               </tr>
                 )
               })
             }
           </tbody>
      </table>
        )
      }
    </div>
  )
}

export default DoneDeal
