import React, { useEffect } from 'react'
import { Button, Card, Col, Modal } from 'antd';
import { useState } from 'react';
import axios from 'axios';


function Apartment() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allcategories,setallcategories]=useState([]);
  const [pageloading,setpageloading]=useState(true);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setNewAppartment()
  };
  const [newAppartment, setNewAppartment] = useState([])
  const getlocations=async()=>{
    let category_data=await axios.get(`https://camp-coding.tech/U_A_D_Walls/user/select_category.php`).catch(err=>{
      console.log(err);
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setpageloading(false);
    })
    console.log(category_data)
    setallcategories(category_data.message);
  }
  useEffect(()=>{
    getlocations();
  },[])

  return <>
  {
    pageloading?(
      [1, 2, 3].map(x => (
        <Col xs={24} md={12} lg={8} key={x}>
          <Card loading minHeight={200} />
        </Col>
      ))
    ):
    (
      <div className="container py-5">
      <div className="row py-5 justify-content-between">
        <div className='right-text col-6'>
          <h4>Add Category</h4>
        </div>
        <div className='left-text col-6 text-end'>
          <button className='btn btn-outline-info' type="button"
          onClick={showModal}>
            Add Category
          </button>
        </div>

  <Modal centered title="Add Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <form>
        <input type='text' className='w-100 form-control mb-3' onChange={e=>setNewAppartment(e.target.value)}  value={newAppartment}  placeholder='Add Category'/>
      </form>
  </Modal>
      </div>
      <div className='row'>
          <div className='col-md-12'>
          <table class="table table-hover ">
  <thead>
  <tr>
  <th scope="col">id</th>
  <th scope="col">Category Name</th>
  {/* <th scope="col">Edit</th> */}
  </tr>
  </thead>
  <tbody>
    {
      allcategories.map((item,index)=>{
        return(
          <tr>
            <td>{item.category_id}</td>
            <td>{item.category_name}</td>
          </tr>
        )
      })
    }

  </tbody>
  </table>

          </div>

      </div>
    </div>
    )
  }
</>
}

export default Apartment
