import avatar1 from "./../../assets/images/users/avatar-1.jpg";
import avatar2 from "./../../assets/images/users/avatar-2.jpg";
import avatar3 from "./../../assets/images/users/avatar-3.jpg";
import avatar4 from "./../../assets/images/users/avatar-4.jpg";
import avatar5 from "./../../assets/images/users/avatar-5.jpg";
import avatar6 from "./../../assets/images/users/avatar-6.jpg";
import avatar7 from "./../../assets/images/users/avatar-7.jpg";
import avatar8 from "./../../assets/images/users/avatar-8.jpg";
import avatar9 from "./../../assets/images/users/avatar-9.jpg";
import avatar10 from "./../../assets/images/users/avatar-10.jpg";

export const userList = [
    {
        name: [avatar1, "Simon Ryles"],
        Position: "Full Stack Developer",
        Email: "SimonRyles@symox.com",
        ViewDetails: "$400"
    },
    {
        name: [avatar2, "Marion Walker"],
        Position: "Frontend Developer",
        Email: "MarionWalker@symox.com",
        ViewDetails: "$452"
    },
    {
        name: [avatar3, "Marion Walker"],
        Position: "UI/UX Designer",
        Email: "FrederickWhite@symox.com",
        ViewDetails: "$632"
    },
    {
        name: [avatar4, "Shanon Marvin"],
        Position: "Backend Developer",
        Email: "ShanonMarvin@symox.com",
        ViewDetails: "$521"
    },
    {
        name: [avatar5, "Mark Jones"],
        Position: "Frontend Developer",
        Email: "MarkJones@symox.com",
        ViewDetails: "$521"
    },
    {
        name: [avatar6, "Janice Morgan"],
        Position: "Backend Developer",
        Email: "JaniceMorgan@symox.com",
        ViewDetails: "$365"
    },
    {
        name: [avatar7, "Patrick Petty"],
        Position: "UI/UX Designer",
        Email: "PatrickPetty@symox.com",
        ViewDetails: "$452"
    },
    {
        name: [avatar8, "Marilyn Horton"],
        Position: "Backend Developer",
        Email: "MarilynHorton@symox.com",
        ViewDetails: "$365"
    },
    {
        name: [avatar9, "Neal Womack"],
        Position: "Full Stack Developer",
        Email: "NealWomack@symox.com",
        ViewDetails: "$254"
    },
    {
        name: [avatar10, "Steven Williams"],
        Position: "Frontend Developer",
        Email: "StevenWilliams@symox.com",
        ViewDetails: "$400"
    },
    {
        name: [avatar1, "Stacie Parker"],
        Position: "Full Stack Developer",
        Email: "StacieParker@symox.com",
        ViewDetails: "$400"
    },
    {
        name: [avatar2, "Betty Wilson"],
        Position: "Frontend Developer",
        Email: "BettyWilson@symox.com",
        ViewDetails: "$452"
    },
    {
        name: [avatar3, "Roman Crabtree"],
        Position: "UI/UX Designer",
        Email: "RomanCrabtree@symox.com",
        ViewDetails: "$632"
    },
    {
        name: [avatar4, "Marisela Butler"],
        Position: "Backend Developer",
        Email: "MariselaButler@symox.com",
        ViewDetails: "$521"
    },
    {
        name: [avatar5, "Roger Slayton"],
        Position: "Frontend Developer",
        Email: "RogerSlayton@symox.com",
        ViewDetails: "$521"
    },
    {
        name: [avatar6, "Barbara Torres"],
        Position: "Backend Developer",
        Email: "BarbaraTorres@symox.com",
        ViewDetails: "$365"
    },
    {
        name: [avatar7, "Daniel Rigney"],
        Position: "UI/UX Designer",
        Email: "DanielRigney@symox.com",
        ViewDetails: "$452"
    },
    {
        name: [avatar8, "Kenneth Linck"],
        Position: "Backend Developer",
        Email: "KennethLinck@symox.com",
        ViewDetails: "$365"
    },
    {
        name: [avatar9, "Felix Perry"],
        Position: "Full Stack Developer",
        Email: "FelixPerry@symox.com",
        ViewDetails: "$254"
    },
    {
        name: [avatar10, "Willie Verner"],
        Position: "Frontend Developer",
        Email: "WillieVerner@symox.com",
        ViewDetails: "$400"
    },
]